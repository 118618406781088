<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Modifier le mot de passe</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Ancien mot de passe</label>
                    <input v-model="form.old_password" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.old_password" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Nouveau mot de passe</label>
                    <input v-model="form.new_password" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.new_password" :key="err.id">
                        {{ err }}
                    </small>
                </form>
            </div>
            <div class="buttons">
                <button @click="reset" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'

export default {

    data() {
        return {
            form: {
                old_password: '',
                new_password: ''
            },
            data_error: {},
            loading: false,
            btn: 'Modifier'
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        reset() {
            if (confirm("Voulez-vous vraiment modifier le mot de passe?")) {
                this.loading = true;
                let data = {
                    old_password: this.form.old_password,
                    new_password: this.form.new_password,
                };

                axios.put(`/reset-password/`, data, this.headers)
                    .then(() => {
                        this.loading = false;
                        // Reset form fields
                        this.form.old_password = '';
                        this.form.new_password = '';
                        // Close the password modal
                        this.close();
                        this.$toast.success("Mot de passe a été modifié avec succès");
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            }
        },

    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    },
    mounted() {
    this.$store.state.user = JSON.parse(localStorage.getItem('user'))
    },
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>