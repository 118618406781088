// import axiosMethods from "./axiosMethods"
// import marked from 'marked'


export default {
    // mixins: [axiosMethods],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    methods: {
        getHeaders() {
            return {
                headers: {
                    'Authorization': 'Bearer ' + this.user.access
                }
            };
        },
        searchInArray(arrayList, searchText) {
            //method to make a search in Array
            if (Array.isArray(arrayList)) {
                return arrayList.filter(
                    e => JSON.stringify(e)
                        .toLowerCase(e)
                        .includes(searchText.toLowerCase())
                )
            }
            return arrayList
        },
        getUsers(url = null) {
            if (!url) {
                url = '/users/';
            }
            this.axios.get(url, this.headers)
                .then(response => {
                    this.$store.state.users = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getPeriod(url = null) {
            if (!url) {
                url = '/period/';
            }
            this.axios.get(url, this.headers)
                .then(response => {
                    this.$store.state.periods = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCellHost(url = null) {
            if (!url) {
                url = '/cell-hosts/';
            }
            this.axios.get(url, this.headers)
                .then(response => {
                    this.$store.state.cellHosts = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCells() {
            this.axios.get('/cell/', this.headers
            ).then(response => {
                this.$store.state.cell = response.data;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getFamilyLeader(url = null) {
            if (!url) {
                url = '/family/';
            }
            this.axios.get(url, this.headers)
                .then(response => {
                    this.$store.state.family = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        cutText(text) {
            let maxLength = 15;
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        // markdownContent(markdown) {
        //     return marked(markdown)
        // }
    },
    computed: {
        logged_in() {
            return this.$store.getters.getLogged_in;
        },
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        },
    },
    mounted() {
        if (this.logged_in && this.$store.state.user.role === 'Staff') {
            console.log('you are logged in!!, true')
            this.getUsers()
            this.getPeriod()
            this.getCellHost()
            this.getCells()
            this.getFamilyLeader()
        } else {
            console.log('you are logged out!!', this.logged_in)
        }
    }
}