<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Rapport de la famille</h5>
                <button class="backBash" @click="goDashboard">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <input class="dateCenter" type="date" v-model="selectedDate">
                    <div class="card-container">
                        <div class="card" v-for="cell in this.$store.state.cell" :key="cell.id">
                            <div class="card-header">
                                {{ cell.cell }}
                            </div>
                            <label class="card-title">Accompagnement</label>
                            <div class="card-label">
                                <input type="radio" :id="'phone_Followup_' + cell.id" value="phone_Followup"
                                    v-model="cell.Followup">
                                <label :for="'phone_Followup_' + cell.id">Par téléphone</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'physical_Followup_' + cell.id" value="physical_Followup"
                                    v-model="cell.Followup">
                                <label :for="'physical_Followup_' + cell.id">Physique</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'no_' + cell.id" value="no" v-model="cell.Followup">
                                <label :for="'no_' + cell.id">No</label>
                            </div>
                            <div class="card-inputs">
                                <input type="text" placeholder="Responsable" v-model="cell.responsible" required>
                                <input type="text" placeholder="thème" v-model="cell.topic" required>
                                <input type="text" placeholder="Observation" v-model="cell.observation" required>
                            </div>
                        </div>
                    </div>
                    <label class="placeholder">Commentaire:</label>
                    <input type="text" placeholder="Commentaire (defis, success...)" v-model="reportForm.comment"
                        required>
                    <small v-for="err in data_error?.comment" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Evénements après Mardi:</label>
                    <input type="text" placeholder="Evénements après Mardi" v-model="reportForm.after_cell_event"
                        required>
                    <label class="placeholder">Suggestions ou Urgence:</label>
                    <input type="text" placeholder="Suggestions ou Urgence" v-model="reportForm.suggestion_or_emergency"
                        required>
                </form>
            </div>
            <div class="buttons">
                <button @click="onSubmit" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    data() {
        return {
            selectedDate: new Date().toISOString().substr(0, 10),
            reportForm: {
                id: 1,
                comment: '',
                after_cell_event: '',
                suggestion_or_emergency: '',
                cell_date: new Date().toISOString().substr(0, 10),
            },
            errorMessage: '',
            btn: 'Envoyer',
            loading: false
        }
    },
    components: {
    },
    methods: {
        goDashboard() {
            this.$router.push('/dashboard/family');
        },
        getFamilyCellLeader() {
            this.axios.get('/family-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        onSubmit() {
            this.loading = true

            const family_id = this.$store.state.family[0].id

            const observaParticulaForm = {
                id: 1,
                comment: this.reportForm.comment,
                after_cell_event: this.reportForm.after_cell_event,
                suggestion_or_emergency: this.reportForm.suggestion_or_emergency,
                date: this.selectedDate,
                family: family_id,
            };

            this.axios.post('/family-observation-particularity/', observaParticulaForm, this.getHeaders())
                .then(response => {
                    this.loading = false;
                    console.log(response);

                    this.$store.state.cell.forEach(cell => {
                        const familyReportData = {
                            cell: cell.id,
                            Followup: cell.Followup,
                            responsible: cell.responsible,
                            topic: cell.topic,
                            observation: cell.observation,
                            date: this.selectedDate,
                            family: this.$store.state.family[0].id,
                        };
                        this.axios.post('/family-report/', familyReportData, this.getHeaders())
                            .then(response => {
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                                this.errorMessage = 'Please Selectionnez !!'
                                this.loading = false;
                            });
                    });
                    this.goDashboard()
                    this.loading = false;
                    this.$toast.success(`Envoyé avec succès`, {
                        position: "bottom",
                        maxToasts: 1
                    })
                })
                .catch(error => {
                    this.data_error = error.response?.data
                    console.log(error);
                    this.$toast.error(error, {
                        position: "bottom",
                        maxToasts: 1
                    })
                    this.loading = false;
                });
        },
        getfamilyLeader() {
            this.axios.get('/logged-family/', this.getHeaders()
            ).then(response => {
                this.$store.state.family = response.data.results;
                this.$store.state.familyOgi = this.$store.state.family[0].family
            })
                .catch(error => {
                    console.log(error)
                })
        },

    },
    computed: {
    },
    mounted() {
        this.getfamilyLeader();
        this.getFamilyCellLeader();
    },
}
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>