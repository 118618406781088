<template>
    <div class="content-ogi">

        <div class="parent-table">
            <div class="filter_pr">
            <h3>Les rapports des familles de la {{ this.$store.state.zoneOgi }}</h3>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Famille</th>
                            <th>Cellule</th>
                            <th>Accompagnement</th>
                            <th>Responsable</th>
                            <th>Theme développée</th>
                            <th>Observation</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.$store.state.familyReport" :key="item.id">
                            <td>{{ item.family_name }}</td>
                            <td>{{ item.cell_name }}</td>

                            <td v-if="item.Followup === 'phone_Followup'">Par téléphone</td>
                            <td v-else-if="item.Followup === 'physical_Followup'">Physique</td>
                            <td v-else>no</td>

                            <td>{{ item.responsible }}</td>
                            <td>{{ item.topic }}</td>
                            <td>{{ item.observation }}</td>
                            <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <button @click="decrementPageFamily" :disabled="currentPageFamily === 1">&lt;</button>
                    <button v-for="pageNumberFamily in displayedPagesFamily" :key="pageNumberFamily"
                        @click="getZoneFamilyReport(pageNumberFamily)" :class="{ active: currentPageFamily === pageNumberFamily }">{{pageNumberFamily }}</button>
                    <span v-if="totalPagesFamily > displayedPagesFamily[displayedPagesFamily.length - 1]">...</span>
                    <button v-if="!displayedPagesFamily.includes(totalPagesFamily)" @click="getZoneFamilyReport(totalPagesFamily)">
                        {{ totalPagesFamily }}
                    </button>
                    <button @click="incrementPageFamily" :disabled="currentPageFamily === totalPagesFamily">&gt;</button>
                </div>
            </div>
        </div>
        <div class="parent-table">
            <div class="filter_pr">
                <h3>Les rapports des cellules de la {{ this.$store.state.zoneOgi }}</h3>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Cellule</th>
                            <th>Visiteurs sauvé</th>
                            <th>Visiteurs non sauvé</th>
                            <th>Sauvé pendant la cellule</th>
                            <th>Facilitateur</th>
                            <th>Moderateur</th>
                            <th>Commentaire</th>
                            <th>Evénement après Mardi</th>
                            <th>Suggestion & urgence</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.$store.state.cellReport" :key="item.id">
                            <td>{{ item.cell_name }}</td>
                            <td>{{ item.saved_visitors }}</td>
                            <td>{{ item.no_saved_visitors }}</td>
                            <td>{{ item.saved_during_cell }}</td>
                            <td>{{ item.word_facilitator }}</td>
                            <td>{{ item.moderator }}</td>
                            <td>{{ item.comment }}</td>
                            <td>{{ item.after_cell_event }}</td>
                            <td>{{ item.suggestion_or_emergency }}</td>
                            <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                    <button v-for="pageNumber in displayedPages" :key="pageNumber"
                        @click="getZoneCellReport(pageNumber)" :class="{ active: currentPage === pageNumber }">{{pageNumber }}</button>
                    <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                    <button v-if="!displayedPages.includes(totalPages)" @click="getZoneCellReport(totalPages)">
                        {{ totalPages }}
                    </button>
                    <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
                </div>
            </div>
        </div>

        <br>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,

            errorMessageFamily: '',
            previousUrlFamily: null,
            nextUrlFamily: null,
            currentPageFamily: 1,
            totalPagesFamily: 1,
            pageSizeFamily: 40,
        }
    },
    components: {
        // moment,
    },
    methods: {
        /// For Cell ///
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getZoneCellReport(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getZoneCellReport(this.currentPage); // Pass current page number
            }
        },
        getZoneCellReport(pageNumber = null) {
            let url = `/logged-zone-CellReport/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.cellReport = response.data.results;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        /// For family ///
        incrementPageFamily() {
            if (this.nextUrlFamily) {
                this.currentPageFamily++;
                this.getZoneFamilyReport(this.currentPageFamily); // Pass current page number
            }
        },
        decrementPageFamily() {
            if (this.previousUrlFamily) {
                this.currentPageFamily--;
                this.getZoneFamilyReport(this.currentPageFamily); // Pass current page number
            }
        },
        getZoneFamilyReport(pageNumberFamily = null) {
            let url = `/logged-zone-FamilyReport/`;
            if (pageNumberFamily !== null) {
                url += `?page=${pageNumberFamily}`;
                this.currentPageFamily = pageNumberFamily;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.familyReport = response.data.results;
                    this.previousUrlFamily = response.data.previous;
                    this.nextUrlFamily = response.data.next;
                    this.totalPagesFamily = Math.ceil(response.data.count / this.pageSizeFamily);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getZoneLeader() {
            this.axios.get('/logged-zone/', this.getHeaders()
            ).then(response => {
                this.$store.state.zone = response.data.results;
                this.$store.state.zoneOgi = this.$store.state.zone[0].name
            })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
        displayedPagesFamily() {
            const rangeStart = Math.max(1, this.currentPageFamily - 1);
            const rangeEnd = Math.min(this.totalPagesFamily, this.currentPageFamily + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        }
    },
    mounted() {
        this.getZoneCellReport();
        this.getZoneFamilyReport();
        this.getZoneLeader();
    },
}
</script>

<style>

</style>