<template>
    <NavBar />
    <div class="articles-container">
        
        <div class="article-detail">
            <img v-if="article.youtube_link === null || article.youtube_link === ''" :src="`${article.poster}`"
                loading="lazy" alt="..." />
            <iframe v-else :src="article.youtube_link" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <div class="article-detail-items">
                <span class="article-header1">{{ article.title }}</span>
                <div class="detail-items">
                    <span class="article-category">{{ article.category_name }}</span>
                    <span class="detail-date">Mis à jour le {{ moment(article.updated_at).format('DD MMMM YYYY')
                        }}</span>
                </div>
                <div v-html="article.content_html"></div>
                <div class="image-container">
                    <div class="image-wrapper" v-for="(item, index) in displayedPhotos" :key="index"
                        :class="{ 'blur-effect': index === 3 }" @click="showPhotos(article.id)">
                        <div class="image-content" :style="{ backgroundImage: `url(${item.photo})` }"></div>
                        <div class="more-link-wrapper" v-if="index === 3 && article.photos.length > 4">
                            <span class="more-link">
                                <span class="more-icon">+</span>
                                <span class="more-count">{{ article.photos.length - 3 }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <span class="article-header2">Auteurs</span>
                <div class="content-container" v-for="(item, index) in article?.authors" :key="index">
                    <div class="people">
                        <img :src="item.photo" loading="lazy" alt="...">
                        <span class="article-header3">{{ item.full_name }}</span>
                    </div>
                    <span>{{ item.bio }}</span>
                </div>
                <!-- <span class="article-header2">Commentaire</span>
            <div class="content-container" v-for="(item, index) in displayedComments" :key="index">
                <span>{{ item.comment }}</span>
            </div> -->
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import NavBar from '@/components/NavBar.vue';

export default {
    data() {
        return {
            article: '',
            moment: moment,
        }
    },
    components: {
        NavBar
    },
    methods: {
        showPhotos(id) {
            this.$router.push(`/blog/photos/${id}`)
        },
        getArticle() {
            this.axios.get('articles/' + this.$route.params.id).then(
                response => {
                    console.log(response)
                    this.article = response?.data;
                }).catch(error => {
                    console.log(error)
                })
        },
    },
    computed: {
        displayedPhotos() {
            return this.article.photos?.slice(0, 4) || [];
        },
        displayedComments() {
            return this.article.comments?.slice(0, 4) || [];
        },
    },
    mounted() {
        this.getArticle();
    },
}

</script>