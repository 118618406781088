<template>
    <NavBar />
    <div class="container-card">
        <div class="login-card">
            <img :src="'/static/logo.png'" alt="">
            <div class="login-form">
                <h2>Se connecter</h2>
                <form @submit.prevent="onLogin">
                    <label>Nom d'utilisateur</label>
                    <input type="text" v-model="form.username" required>
                    <label>Mot de passe</label>
                    <input type="password" v-model="form.password" required>
                    <small>{{ data_error.detail }}</small>
                    <button type="submit">{{ loading ? "Chargement..." : btn }}</button>
                </form>
            </div>
        </div>
        <div class="welcome-text">
            <h2>Vision</h2>
            <p>MINEVAM KIGOBE veut être une famille des familles qui adorent (adoration) Dieu, qui croissent à la
                ressemblance du Christ, qui servent les autres (ministère ou service) et qui atteignent le monde
                (Mission).
            </p>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import NavBar from '@/components/NavBar.vue';

export default {
    components: {
    NavBar,
    // OurFooter
  },
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
            data_error: {},
            errorMessage: "",
            btn: 'Connexion',
            loading: false
        };
    },
    methods: {
        onLogin() {
            this.loading = true

            this.axios
                .post('/login/', this.form)
                .then((response) => {
                    console.log(response);
                    this.$store.commit("login", JSON.stringify(response.data));
                    const audio = new Audio(require('@/assets/audio/arrow.mp3'));
                    audio.play();
                    localStorage.setItem("user", JSON.stringify(response.data));
                    store.dispatch('setLogged_in', true);
                    
                    this.$store.state.user = JSON.parse(localStorage.getItem('user'))

                    const role = this.$store?.state?.user?.role

                    //redirect user based on role
                    if (role === 'Cell') {
                        this.$router.push('/dashboard/cell');
                    } else if (role === 'Family') {
                        this.$router.push('/dashboard/family');
                    } else if (role === 'Zone') {
                        this.$router.push('/dashboard/zone');
                    } else if (role === 'District') {
                        this.$router.push('/dashboard/district');
                    } else if (role === 'Staff') {
                        this.$router.push('/dashboard/staff');
                    } else if (role === 'Member') {
                        this.$router.push('/dashboard/member');
                    } else {
                        this.$router.push('/login');
                    }
                    this.loading = false

                })
                .catch(error => {
                    console.log(error);
                    this.data_error = error.response?.data
                    this.$toast.error("Nom d'utilisateur ou Mot de passe incorrect")
                    this.loading = false
                });
        },
    },
};

</script>

