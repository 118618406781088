<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Membre</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form" enctype="multipart/form-data">
                    <label class="placeholder">Nom</label>
                    <input id="lastName" v-model="form.lastName" type="text" required />
                    <small v-for="err in data_error?.lastName" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="firstName" class="placeholder">Prénom</label>
                    <input id="firstName" v-model="form.firstName" type="text" required />
                    <small v-for="err in data_error?.firstName" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="phone1" class="placeholder">Téléphone 1</label>
                    <input id="phone1" v-model="form.phone1" type="text" />
                    <label for="phone2" class="placeholder">Téléphone 2</label>
                    <input id="phone2" v-model="form.phone2" type="text" />
                    <label for="address" class="placeholder">Adresse</label>
                    <input id="address" v-model="form.address" type="text" />
                    <label>Sexe</label>
                    <div class="t-colomn">
                        <span>
                            <input type="radio" id="Male" value="Male" v-model="form.gender">
                            <label for="Male">Homme</label>
                        </span>
                        <span>
                            <input type="radio" id="Female" value="Female" v-model="form.gender">
                            <label for="Female">Femme</label>
                        </span>
                    </div>

                    <label>Etat civil</label>
                    <div class="t-colomn">
                        <span>
                            <input type="radio" id="Single" value="Single" v-model="form.civilStatus">
                            <label for="Single">Célibataire</label>
                        </span>
                        <span>
                            <input type="radio" id="Married" value="Married" v-model="form.civilStatus">
                            <label for="Married">Marrié(e)</label>
                        </span>
                        <span>
                            <input type="radio" id="Divorced" value="Divorced" v-model="form.civilStatus">
                            <label for="Divorced">Divorcé(e)</label>
                        </span>
                        <span>
                            <input type="radio" id="Widow" value="Widow" v-model="form.civilStatus">
                            <label for="Widow">Veuf(ve)</label>
                        </span>
                    </div>
                    <div class="input-custom">
                        <input id="image1" type="file" accept=".jpeg,.jpg,.png" @change="e => loadImage(e)">
                        <label for="Photo" class="Photo">Photo</label>
                        <div class="imageLoad">
                            <div class="image" @click="clickOn('image1')">
                                <img :src="!!form.photo ? form.photo : '/static/logo.png'" alt="">
                            </div>
                        </div>
                        <small>{{ logs }}</small>
                    </div>
                    <label class="placeholder">Promotion:</label>
                    <select v-model="form.period">
                        <option v-for="item in this.$store.state.all_periods" :key="item.id" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                    <small v-for="err in data_error?.period" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="" class="">Baptisé(e)</label>
                    <div class="t-colomn">
                        <span>
                            <input type="radio" id="baptised" :value="true" v-model="form.baptised">
                            <label for="baptised">Oui</label>
                        </span>
                        <span>
                            <input type="radio" id="n_baptised" :value="false" v-model="form.baptised">
                            <label for="n_baptised">Non</label>
                        </span>
                    </div>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    props: ['editMember'],
    components: {
    },
    data() {
        return {
            //for search user
            result: "",
            results: [],
            founds: [],
            keyword: "",
            cellName: null,

            moment: moment,
            now_date: new Date().toISOString().substr(0, 10),

            logs: "",
            searchPeriod: '',
            searchCell: '',

            form: {
                id: '',
                firstName: "",
                lastName: "",
                phone1: "",
                phone2: "",
                address: "",
                gender: "Male",
                civilStatus: 'Single',
                photo: null,
                bornAgainShortStoryAndWhenWhere: "",
                baptised: false,
                baptemChurchDate: "",
                recentChurchs: "",
                whyYouChangeChurch: "",
                haveYouSayGoodbyeToRecentChurch: "Oya",
                whatDoYouWantChurchToDoForYou: "",
                wantToTalkWithLeader: false,
                talk: false,
                talkWith: "",
                now_member: false,
                active: true,
                reason_for_departure: "",
                cell: null,
                period: null
            },
            data_error: {},

            loading: false,
            btn: 'Enregistrer'
        };
    },
    methods: {
        close() {
            this.$emit('close')
        },
        fetchMembers() {
            this.$emit('fetchMembers')
        },
        updatePeriod(id) {
            this.form.period = id;
            this.searchPeriod = this.filteredPromotions.find(item => item.id === id)?.name || "";
        },
        updateCell(id) {
            this.form.cell = id;
            this.searchCell = this.filteredCells.find(item => item.id === id)?.cell || "";
        },
        clickOn(id) {
            let img = document.getElementById(id)
            img.click()
        },
        loadImage(event) {
            let file = event.target.files[0]
            if (file.size > 900_000) {
                this.logs = "Ne Pas depasser 800ko"
            } else {
                this.logs = ""
                let fr = new FileReader();
                let vue = this
                fr.onload = function () {
                    switch (event.target.id) {
                        case "image1":
                            vue.document1 = file
                            vue.form.photo = fr.result
                            break;
                        // case "image2":
                        // vue.document2 = file
                        // vue.img2_src = fr.result
                        // break;
                        default:
                            vue.document1 = file
                            vue.form.photo = fr.result
                            break;
                    }
                }
                fr.readAsDataURL(file);
            }
        },
        save() {
            if (this.editMember) {
                this.loading = true;
                const cell_id = this.$store.state.cell[0].id

                let formOgi = new FormData()
                formOgi.append("firstName", this.form.firstName)
                formOgi.append("lastName", this.form.lastName)
                formOgi.append("phone1", this.form.phone1)
                formOgi.append("phone2", this.form.phone2)
                formOgi.append("address", this.form.address)
                formOgi.append("civilStatus", this.form.civilStatus)
                formOgi.append("gender", this.form.gender)

                if (this.document1) {
                    formOgi.append("photo", this.document1);
                }

                formOgi.append("bornAgainShortStoryAndWhenWhere", this.form.bornAgainShortStoryAndWhenWhere)
                formOgi.append("baptised", this.form.baptised)
                formOgi.append("baptemChurchDate", this.form.baptemChurchDate)
                formOgi.append("recentChurchs", this.form.recentChurchs)
                formOgi.append("whyYouChangeChurch", this.form.whyYouChangeChurch)
                formOgi.append("haveYouSayGoodbyeToRecentChurch", this.form.haveYouSayGoodbyeToRecentChurch)
                formOgi.append("whatDoYouWantChurchToDoForYou", this.form.whatDoYouWantChurchToDoForYou)
                formOgi.append("wantToTalkWithLeader", this.form.wantToTalkWithLeader)
                formOgi.append("talk", this.form.talk)
                formOgi.append("talkWith", this.form.talkWith)
                formOgi.append("now_member", this.form.now_member)
                formOgi.append("active", this.form.active)
                formOgi.append("reason_for_departure", this.form.reason_for_departure)
                formOgi.append("cell", cell_id)
                formOgi.append("period", this.form.period)

                this.axios.put(`/cell-members/${this.form.id}/`, formOgi, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.fetchMembers();
                        this.$toast.success(`Modifié avec succès`, {
                            position: "bottom",
                            maxToasts: 1
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            } else {
                this.loading = true;
                const cell_id = this.$store.state.cell[0].id

                let formOgi = new FormData()

                formOgi.append("firstName", this.form.firstName)
                formOgi.append("lastName", this.form.lastName)
                formOgi.append("phone1", this.form.phone1)
                formOgi.append("phone2", this.form.phone2)
                formOgi.append("address", this.form.address)
                formOgi.append("civilStatus", this.form.civilStatus)
                formOgi.append("gender", this.form.gender)

                if (this.document1) {
                    formOgi.append("photo", this.document1);
                }

                formOgi.append("bornAgainShortStoryAndWhenWhere", this.form.bornAgainShortStoryAndWhenWhere)
                formOgi.append("baptised", this.form.baptised)
                formOgi.append("baptemChurchDate", this.form.baptemChurchDate)
                formOgi.append("recentChurchs", this.form.recentChurchs)
                formOgi.append("whyYouChangeChurch", this.form.whyYouChangeChurch)
                formOgi.append("haveYouSayGoodbyeToRecentChurch", this.form.haveYouSayGoodbyeToRecentChurch)
                formOgi.append("whatDoYouWantChurchToDoForYou", this.form.whatDoYouWantChurchToDoForYou)
                formOgi.append("wantToTalkWithLeader", this.form.wantToTalkWithLeader)
                formOgi.append("talk", this.form.talk)
                formOgi.append("talkWith", this.form.talkWith)
                formOgi.append("now_member", this.form.now_member)
                formOgi.append("active", this.form.active)
                formOgi.append("reason_for_departure", this.form.reason_for_departure)
                formOgi.append("cell", cell_id)
                formOgi.append("period", this.form.period)

                this.axios.post('/members/', formOgi, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.fetchMembers();
                        this.$toast.success(`Ajouté avec succès`, {
                            position: "bottom",
                            maxToasts: 1
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            }
        }
    },

    mounted() {
        this.fetchMembers()

        if (this.editMember) {

            this.form.id = this.$store.state.members.id
            this.form.firstName = this.$store.state.members.firstName
            this.form.lastName = this.$store.state.members.lastName
            this.form.phone1 = this.$store.state.members.phone1
            this.form.phone2 = this.$store.state.members.phone2
            this.form.address = this.$store.state.members.address
            this.form.civilStatus = this.$store.state.members.civilStatus
            this.form.gender = this.$store.state.members.gender
            this.form.photo = this.$store.state.members.photo
            this.form.bornAgainShortStoryAndWhenWhere = this.$store.state.members.bornAgainShortStoryAndWhenWhere
            this.form.baptised = this.$store.state.members.baptised
            this.form.baptemChurchDate = this.$store.state.members.baptemChurchDate
            this.form.recentChurchs = this.$store.state.members.recentChurchs
            this.form.whyYouChangeChurch = this.$store.state.members.whyYouChangeChurch
            this.form.haveYouSayGoodbyeToRecentChurch = this.$store.state.members.haveYouSayGoodbyeToRecentChurch
            this.form.whatDoYouWantChurchToDoForYou = this.$store.state.members.whatDoYouWantChurchToDoForYou
            this.form.wantToTalkWithLeader = this.$store.state.members.wantToTalkWithLeader
            this.form.talk = this.$store.state.members.talk
            this.form.talkWith = this.$store.state.members.talkWith
            this.form.now_member = this.$store.state.members.now_member
            this.form.active = this.$store.state.members.active
            this.form.reason_for_departure = this.$store.state.members.reason_for_departure
            this.form.cell = this.$store.state.members.cell.id
            this.form.period = this.$store.state.members.period

            this.searchPeriod = this.$store.state.members.period_name
            this.searchCell = this.$store.state.members.cell.cell

            this.btn = 'Modifier'
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access,
                    "Content-Type": "multipart/form-data" // Set the content type manually
                }
            }
        },
        filteredCells() {
            return this.$store.state.cell.filter(cell =>
                cell.cell.toLowerCase().includes(this.searchCell.toLowerCase()) ||
                cell.chief.toLowerCase().includes(this.searchCell.toLowerCase()) ||
                cell.cellHost_name.toLowerCase().includes(this.searchCell.toLowerCase())
            );
        },
        filteredPromotions() {
            return this.$store.state.periods.filter(period =>
                period.name.toLowerCase().includes(this.searchPeriod.toLowerCase())
            );
        }
    }
};
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>
