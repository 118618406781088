<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Dépense</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Type de depense</label>
                    <select v-model="form.outcomeType" name="" id="">
                        <option selected value="" disabled>--------</option>
                        <option v-for="outType in outcomeTypes " :key="outType.id" :value="outType.id">{{ outType.name }}
                        </option>
                    </select>
                    <small v-for="err in data_error?.outcomeType" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Beneficiaire</label>
                    <select v-model="form.beneficiary" name="" id="">
                        <option selected value="" disabled>--------</option>
                        <option v-for="ben in beneficiary " :key="ben.id" :value="ben.id">{{ ben.name }}</option>
                    </select>
                    <small v-for="err in data_error?.beneficiary" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Motif</label>
                    <input v-model="form.motif" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.motif" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Montant</label>
                    <input v-model="form.amount" type="number" required placeholder=" " />
                    <small v-for="err in data_error?.amount" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="" class="">Monnaie</label>
                    <div class="t-colomn">
                        <span>
                            <input type="radio" id="BIF" value="BIF" v-model="form.currency">
                            <label for="BIF">BIF</label>
                        </span>
                        <span>
                            <input type="radio" id="USD" value="USD" v-model="form.currency">
                            <label for="USD">USD</label>
                        </span>
                        <span>
                            <input type="radio" id="EURO" value="EURO" v-model="form.currency">
                            <label for="EURO">EURO</label>
                        </span>
                    </div>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['editOutcome'],
    data() {
        return {
            form: {
                motif: '',
                amount: '',
                currency: 'BIF',
                outcomeType: '',
                beneficiary: ''
            },
            beneficiary: [],
            outcomeTypes: [],
            data_error: {},

            btn: 'Enregistrer',
            loading: false,
        }
    },
    methods: {
        save() {
            if (this.editOutcome) {
                this.loading = true
                let data = new FormData()
                data.append('motif', this.form.motif)
                data.append('amount', this.form.amount)
                data.append('currency', this.form.currency)
                data.append('outcomeType', this.form.outcomeType)
                data.append('beneficiary', this.form.beneficiary)
                axios.put('/outcome/' + this.$store.state.outcome.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getOutcome()
                        this.$toast.success(`Depense Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('motif', this.form.motif)
                data.append('amount', this.form.amount)
                data.append('currency', this.form.currency)
                data.append('outcomeType', this.form.outcomeType)
                data.append('beneficiary', this.form.beneficiary)
                axios.post('/outcome/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getOutcome()
                        this.$toast.success(`Depense ajouté`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        close() {
            this.$emit('close')
        },
        getOutcome() {
            this.$emit('getOutcome')
        },
        getOutcomeType() {
            axios.get('/outcome-type', this.headers)
                .then((res) => {
                    this.outcomeTypes = res.data.results
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getBeneficiary() {
            axios.get('/beneficiary', this.headers)
                .then((res) => {
                    this.beneficiary = res.data.results
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getOutcome()
        this.getBeneficiary()
        this.getOutcomeType()
        
        if (this.editOutcome) {
            this.btn = 'Modifier'
            this.form.motif = this.$store.state.outcome.motif
            this.form.amount = this.$store.state.outcome.amount
            this.form.currency = this.$store.state.outcome.currency
            this.form.outcomeType = this.$store.state.outcome.outcomeType
            this.form.beneficiary = this.$store.state.outcome.beneficiary
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>