<template>
    <div class="parent-table">
        <h3>Les Membres</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <!-- <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button> -->
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Nom et Prénom</th>
                        <th>Phone</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>
                            <img :src="item.photo" class="t-photo" />
                        </td>

                        <td :class="{ 't-member': item.now_member, 't-no-member': !item.now_member }">
                            {{ item.lastName }} {{ item.firstName }}
                        </td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.phone1">{{ item.phone1 }}</a></span>
                            <span><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a></span>
                        </td>
                        <td class="action">
                            <button class="btn-action" @click="editMember(item)">
                                <i class="fas fa-edit"></i>
                            </button>
                            <button class="btn-action" @click="showMember(item)">
                                <i class="fas fa-eye"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>

                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>NB: Membres avec la couleur verte sont en processus d'intégration </p>
        <member_modal_cell v-if="modal" @close="close" @fetchMembers="fetchMembers" :editMember="modify">
        </member_modal_cell>
        <M_detail_modal_cell v-if="showModal" @close="close" @fetchMembers="fetchMembers" :showMember="show">
        </M_detail_modal_cell>
    </div>
</template>

<script>
// import * as XLSX from 'xlsx'
import member_modal_cell from '@/components/Member/member_modal_cell.vue'
import M_detail_modal_cell from '@/components/Member/M_detail_modal_cell.vue'

export default {
    name: 'MemberPage',
    components: {
        // OurPhoto,
        member_modal_cell,
        M_detail_modal_cell
    },
    data() {
        return {
            modal: false,
            modify: false,

            show: false,
            showModal: false,

            searchText: "",
        };
    },
    methods: {
        close() {
            this.modal = false
            this.showModal = false
        },
        editMember(item) {
            this.modify = true
            this.modal = true
            this.showModal = false
            this.$store.state.members = item
        },
        showMember(item) {
            this.show = true
            this.modal = false
            this.showModal = true
            this.$store.state.members = item
        },
        getPeriods() {
            this.axios.get('/period/', this.getHeaders())
                .then(response => {
                    this.$store.state.all_periods = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getCellLeader() {
            this.axios.get('/logged-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
                this.$store.state.cellOgi = this.$store.state.cell[0].cell
            })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchMembers(url = null) {
            if (!url) {
                url = '/cell-members/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.members = response.data.results
                    this.$store.state.membersTot = response.data.count
                    this.$store.state.new_members = response.data.new_members
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // exportExcel() {
        //     const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
        //     const workBook = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
        //     XLSX.writeFile(workBook, 'member.xlsx')
        // }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.members, this.searchText);
        },
    },
    mounted() {
        this.fetchMembers();
        this.getCellLeader()
        this.getPeriods()
    }
};
</script>

<style></style>