<template>
    <div class="parent-table">
        <h3>Les Types de Dépense</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <button @click=searchPage(searchQuery)>Appliquer</button>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.outcomeTypes" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                            <button class="btn-action" @click="editOutcomeType(item)"> <i
                                    class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <outcomeTypes :editOutcomeType="modify" @getOutcomeType="getOutcomeType" v-if="modal" @close="close">
        </outcomeTypes>
    </div>
</template>

<script>
// import OurSuccess from '@/components/OurSuccess.vue'
import outcomeTypes from '@/components/OutcomeTypes/outcomeTypes_modal.vue'

export default {
    name: 'outcomeTypePageZ',
    components: {
        // moment,
        // OurSuccess
        outcomeTypes
    },
    data() {
        return {
            modal: false,
            modify: false,
            success: false,
            outcomeTypeForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            },
            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editOutcomeType(item) {
            this.modal = true
            this.modify = true
            this.$store.state.outcomeType = item
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getOutcomeType(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getOutcomeType(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getOutcomeType(url = null) {
            if (!url) {
                url = '/outcome-type/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.outcomeTypes = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getOutcomeType(`/outcome-type/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
    },
    mounted() {
        this.getOutcomeType();
    }
};
</script>

<style></style>