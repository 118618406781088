<template>
    <div class="parent-table">
        <h3>Les membres du département</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <button @click=searchPage(searchQuery)>Appliquer</button>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Membre</th>
                        <th>Phone</th>
                        <th>Cellule</th>
                        <th>Département</th>
                        <th>Category</th>
                        <th>Objective</th>
                        <th>Chef</th>
                        <th>Chef Phone</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.departmentMembers" :key="item.id">

                        <td>{{ item.id }}</td>

                        <td>{{ item.member?.lastName }} {{ item.member?.firstName }}</td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.member?.phone1">{{ item.member?.phone1 }}</a></span>
                            <span><a :href="'tel:' + item.member?.phone2">{{ item.member?.phone2 }}</a></span>
                        </td>
                        <td>{{ item.member?.cell.cell }}</td>

                        <td>{{ item.department?.name }}</td>
                        <td>{{ item.department?.category_name }}</td>
                        <td>{{ item.department?.objective_name }}</td>
                        <td>{{ item.department?.chief }}</td>
                        <td class="t-colomn"><a :href="'tel:' + item.department?.chiefPhone1">{{
                item.department?.chiefPhone1 }}</a></td>

                        <td>
                            <button class="btn-action" @click="editDeptMember(item)"> <i
                                    class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <depMember_modal v-if="modal" @close="close" :editDeptMember="modify"
            @getDepartmentMember="getDepartmentMember">
        </depMember_modal>
    </div>
</template>

<script>
import depMember_modal from '@/components/Department/depMember_modal.vue';
import * as XLSX from 'xlsx'

export default {
    name: 'departmentPageZ',
    components: {
        // moment,
        // OurSuccess
        depMember_modal
    },
    data() {
        return {
            modal: false,
            modify: false,

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editDeptMember(item) {
            this.modal = true
            this.modify = true
            // this.$store.state.departmentMembers = {
            //     department: item.department,
            //     member: item.member
            // };
            this.$store.state.departmentMembers = item
        },

        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getDepartmentMember(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getDepartmentMember(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getDepartmentMember(url = null) {
            if (!url) {
                url = '/department-member/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.departmentMembers = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getDepartmentMember(`/department-member/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.$store.state.departmentMembers)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'Department-member.xlsx')
        }
    },
    mounted() {
        this.getDepartmentMember();
    }
};
</script>

<style></style>