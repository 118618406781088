<template>

    <div class="parent-table">
        <div class="filter_pr">
            <h3>Les rapports de la cellule</h3>
        </div>
        <!-- <div class="filter_pr">
            <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
            <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
            <button @click="getCellReport()">Trier</button>
        </div> -->
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Cellule</th>
                        <th>Visiteurs sauvé</th>
                        <th>Visiteurs non sauvé</th>
                        <th>Sauvé pendant la cellule</th>
                        <th>Facilitateur</th>
                        <th>Moderateur</th>
                        <th>Commentaire</th>
                        <th>Evénement après Mardi</th>
                        <th>Suggestion & urgence</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.cellReport" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.cell_name }}</td>
                        <td>{{ item.saved_visitors }}</td>
                        <td>{{ item.no_saved_visitors }}</td>
                        <td>{{ item.saved_during_cell }}</td>
                        <td>{{ item.word_facilitator }}</td>
                        <td>{{ item.moderator }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.after_cell_event }}</td>
                        <td>{{ item.suggestion_or_emergency }}</td>
                        <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                <button v-for="pageNumber in displayedPages" :key="pageNumber"
                    @click="getCellReport(pageNumber)" :class="{ active: currentPage === pageNumber }">{{
                        pageNumber }}</button>
                <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                <button v-if="!displayedPages.includes(totalPages)" @click="getCellReport(totalPages)">
                    {{ totalPages }}
                </button>
                <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
            </div>
        </div>
    </div>

    <br>
    <br>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,
            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1
        }
    },
    components: {
        // moment,
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getCellReport(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getCellReport(this.currentPage); // Pass current page number
            }
        },
        getCellReport(pageNumber = null) {
            let url = `/logged-CellReport/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }

            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.cellReport = response.data.results;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
    },
    mounted() {
        this.getCellReport();
    },
}
</script>

<style></style>