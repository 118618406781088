<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Rapport de la cellule</h5>
                <button class="backBash" @click="goDashboard">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <input class="dateCenter" type="date" v-model="selectedDate">
                    <div class="card-container">
                        <div class="card" v-for="member in this.$store.state.members" :key="member.id">
                            <div class="card-header">
                                {{ member.lastName }} {{ member.firstName }}
                            </div>
                            <label class="card-title">Participation</label>
                            <div class="card-label">
                                <input type="radio" :id="'present_' + member.id" value="present"
                                    v-model="member.attendance" required>
                                <label :for="'present_' + member.id">Présent</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'absent_' + member.id" value="absent"
                                    v-model="member.attendance" required>
                                <label :for="'absent_' + member.id">Absent</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'justified_' + member.id" value="justified"
                                    v-model="member.attendance" required>
                                <label :for="'justified_' + member.id">Justifié</label>
                            </div>
                            <span v-if="!member.attendance" class="error">{{ errorMessage }}</span>
                            <label class="card-title">Accompagnement</label>
                            <div class="card-label">
                                <input type="radio" :id="'phone_Followup' + member.id" value="phone_Followup"
                                    v-model="member.Followup" required>
                                <label :for="'phone_Followup' + member.id">Par téléphone</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'physical_Followup' + member.id" value="physical_Followup"
                                    v-model="member.Followup" required>
                                <label :for="'physical_Followup' + member.id">Physique</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'no_' + member.id" value="no" v-model="member.Followup"
                                    required>
                                <label :for="'no_' + member.id">No</label>
                            </div>
                            <span v-if="!member.Followup" class="error">{{ errorMessage }}</span>
                            <label>Observation</label>
                            <input type="text" placeholder="Observation" v-model="member.observation">
                        </div>
                    </div>
                    <label class="placeholder">visiteurs sauvé:</label>
                    <input type="number" placeholder="visiteurs sauvé" v-model="reportForm.saved_visitors" required>
                    <small v-for="err in data_error?.saved_visitors" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">visiteurs non sauvé:</label>
                    <input type="number" placeholder="visiteurs non sauvé" v-model="reportForm.no_saved_visitors"
                        required>
                    <small v-for="err in data_error?.no_saved_visitors" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">sauvé pendant la cellule:</label>
                    <input type="number" placeholder="sauvé pendant la cellule" v-model="reportForm.saved_during_cell"
                        required>
                    <small v-for="err in data_error?.saved_during_cell" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">facilitateur:</label>
                    <input type="text" placeholder="facilitateur" v-model="reportForm.word_facilitator" required>
                    <small v-for="err in data_error?.word_facilitator" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">moderateur:</label>
                    <input type="text" placeholder="moderateur" v-model="reportForm.moderator" required>
                    <small v-for="err in data_error?.moderator" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Commentaire:</label>
                    <input type="text" placeholder="Commentaire (defis, success...):" v-model="reportForm.comment"
                        required>
                    <label class="placeholder">Evénement après Mardi:</label>
                    <input type="text" placeholder="Evénement après Mardi" v-model="reportForm.after_cell_event">
                    <label class="placeholder">Suggestion & urgence:</label>
                    <input type="text" placeholder="Suggestion & urgence" v-model="reportForm.suggestion_or_emergency">

                </form>
            </div>
            <div class="buttons">
                <button @click="onSubmit" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
//   import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            selectedDate: new Date().toISOString().substr(0, 10),
            reportForm: {
                id: 1,
                saved_visitors: '',
                no_saved_visitors: '',
                saved_during_cell: '',
                word_facilitator: '',
                moderator: '',
                comment: '',
                after_cell_event: '',
                suggestion_or_emergency: '',
                cell_date: new Date().toISOString().substr(0, 10),
                // cell: '',
            },
            errorMessage: '',
            btn: 'Envoyer',
            loading: false,
            data_error: {},
        }
    },
    components: {
        // moment,
    },
    methods: {
        goDashboard() {
            this.$router.push('/dashboard/cell');
        },
        fetchMembers() {
            this.axios.get('/cell-members/', this.getHeaders()
            ).then(response => {
                this.$store.state.members = response.data.results
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getCellLeader() {
            this.axios.get('/logged-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
                this.$store.state.cellOgi = this.$store.state.cell[0].cell
            })
                .catch(error => {
                    console.log(error)
                })
        },
        onSubmit() {
            this.loading = true
            const cell_id = this.$store.state.cell[0].id

            const reportFormData = {
                id: 1,
                saved_visitors: this.reportForm.saved_visitors,
                no_saved_visitors: this.reportForm.no_saved_visitors,
                saved_during_cell: this.reportForm.saved_during_cell,
                word_facilitator: this.reportForm.word_facilitator,
                moderator: this.reportForm.moderator,
                comment: this.reportForm.comment,
                after_cell_event: this.reportForm.after_cell_event,
                suggestion_or_emergency: this.reportForm.suggestion_or_emergency,
                cell_date: this.selectedDate,
                cell: cell_id,
            };

            this.axios.post('/cell-report/', reportFormData, this.getHeaders())
                .then(response => {
                    console.log(response);

                    this.$store.state.members.forEach(member => {
                        const attendanceData = {
                            attendance: member.attendance,
                            Followup: member.Followup,
                            observation: member.observation,
                            cell_date: this.selectedDate,
                            cell: this.$store.state.cell[0].id,
                            member: member.id
                        };

                        this.axios.post('/cell-member-attendance/', attendanceData, this.getHeaders())
                            .then(response => {
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                                this.errorMessage = 'Please Selectionnez !!'
                                this.loading = false;
                            });
                    });
                    this.goDashboard()
                    this.loading = false;
                    this.$toast.success(`Envoyé avec succès`, {
                        position: "bottom",
                        maxToasts: 1
                    })
                })
                .catch(error => {
                    this.data_error = error.response?.data
                    console.log(error);
                    this.$toast.error(error, {
                        position: "bottom",
                        maxToasts: 1
                    })
                    this.loading = false;
                });
        },

    },
    mounted() {
        this.fetchMembers();
        this.getCellLeader();
    },
}
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>