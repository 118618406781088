<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Hôte de Cellule</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label for="firstname">Nom</label>
                    <input id="firstname" class="input" v-model="form.lastName" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.lastName" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="firstname">Prenom</label>
                    <input id="firstname" class="input" v-model="form.firstName" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.firstName" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="firstname">Phone 1</label>
                    <input id="firstname" class="input" v-model="form.phone1" type="text" required placeholder=" " />
                    <label for="firstname">Phone 2</label>
                    <input id="firstname" class="input" type="text" v-model="form.phone2" required placeholder=" " />
                    <label for="firstname">Address</label>
                    <input id="firstname" v-model="form.address" class="input" type="text" required placeholder=" " />
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['editCellHost'],
    data() {
        return {
            form: {
                firstName: '',
                lastName: '',
                phone1: '',
                phone2: '',
                address: '',
            },
            data_error: {},

            btn: 'Enregistrer',
            loading: false
        }
    },
    methods: {
        save() {
            if (this.editCellHost) {
                this.loading = true
                let data = new FormData()
                data.append('firstName', this.form.firstName)
                data.append('lastName', this.form.lastName)
                data.append('phone1', this.form.phone1)
                data.append('phone2', this.form.phone2)
                data.append('address', this.form.address)
                axios.put('/cell-hosts/' + this.$store.state.cellHost.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getCellHost()
                        this.$toast.success(`Hôte de cellule Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('firstName', this.form.firstName)
                data.append('lastName', this.form.lastName)
                data.append('phone1', this.form.phone1)
                data.append('phone2', this.form.phone2)
                data.append('address', this.form.address)
                axios.post('/cell-hosts/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getCellHost()
                        this.$toast.success(`Hôte de cellule Ajouté`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        close() {
            this.$emit('close')
        },
        getCellHost() {
            this.$emit('getCellHost')
        }
    },
    mounted() {
        this.getCellHost()
        if (this.editCellHost) {
            this.form.firstName = this.$store.state.cellHost.firstName
            this.form.lastName = this.$store.state.cellHost.lastName
            this.form.phone1 = this.$store.state.cellHost.phone1
            this.form.phone2 = this.$store.state.cellHost.phone2
            this.form.address = this.$store.state.cellHost.address
            this.btn = 'Modifier'
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>