<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />


    <div class="account">
        <div class="title">
            <h3>Compte:</h3>
        </div>
        <strong v-if="$store.state.user.role === 'Staff'"> Administration de MK</strong>
        <hr>
        <h3 class="title">Modifier:</h3>
        <div class="btn">
            <button @click="modal_edit_username = true">Nom d'utilisateur</button>
            <button @click="modal_edit_password = true">Mot de passe</button>
        </div>
        <hr>
        <button class="logout" @click="confirmLogout"><i class="fas fa-sign-out-alt"></i> Deconnexion</button>

        <edit_username v-if="modal_edit_username" @close="close"></edit_username>
        <edit_password v-if="modal_edit_password" @close="close"></edit_password>
    </div>
</template>

<script>
import store from '@/store'
import edit_username from '@/components/User/edit_username.vue'
import edit_password from '@/components/User/edit_password.vue'

export default {
    name: 'OurAccount',
    data() {
        return {
            modal_edit_username: false,
            modal_edit_password: false,
        }
    },
    components: {
        edit_username,
        edit_password
    },
    methods: {
        close() {
            this.modal_edit_username = false
            this.modal_edit_password = false
        },
        confirmLogout() {
            if (confirm('Voulez-vous vraiment vous deconnecter?')) {
                this.logout();
            }
        },
        logout() {
            this.$router.push('/');
            localStorage.removeItem('user');
            store.dispatch('setLogged_in', false);
        },
    },
    mounted() {
        this.$store.state.user = JSON.parse(localStorage.getItem('user'))
    }
}
</script>
<style></style>