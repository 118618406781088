<template>
    <div class="parent-table">
        <h3>Les categories</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Categorie</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.category }}</td>
                        <td>
                            <button class="btn-action" @click="editCategory(item)"> <i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
            <category_modal v-if="modal" @close="close" @getCategory="getCategory" :editCategory="modify">
            </category_modal>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import category_modal from '@/components/Category/category_modal.vue'


export default {
    name: 'categoryPageZ',
    components: {
        // moment,
        // OurSuccess
        category_modal
    },
    data() {
        return {
            result: '',
            modal: false,
            modify: false,
            success: false,
            categoryForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            },
            searchText: "",
            currentPage: 1, // current page number
            categoryPerPage: 15
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editCategory(item) {
            this.modify = true
            this.modal = true
            this.$store.state.category = item
        },
        getCategory() {
            this.axios.get('/category/', this.getHeaders()
            ).then(response => {
                this.$store.state.categories = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'category.xlsx')
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.categories, this.searchText);
        },
    },
    mounted() {
        this.getCategory();
    }
};
</script>

<style></style>