<template>
    <NavBar />
    <div class="articles-container">
        <div class="articles">
            <div class="article-photos" v-for="(item, index) in article?.photos" :key="index">
                <img :src="item.photo" loading="lazy" alt="..." />
                <span>{{ item.description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    data() {
        return {
            article: ''
        }
    },
    components: {
        NavBar,
    },
    methods: {
        getArticle() {
            this.axios.get('articles/' + this.$route.params.id).then(
                response => {
                    console.log(response)
                    this.article = response?.data;
                }).catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getArticle();
    }
}

</script>
