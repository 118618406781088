<template>
    <div class="parent-table">
        <h3>Les Recettes</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText" @keydown.enter="search">
                <button class="btn-action" @click="search">
                    <i class="fas fa-search"></i>
                </button>
                <p>{{ result }}</p>
            </div>
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <button @click=searchPage(searchQuery)>Appliquer</button>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Motif</th>
                        <th>Amount</th>
                        <th>Monnaie</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.motif }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.currency }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                        <td>
                            <button class="btn-action" @click="editIncome(item)"> <i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <addIncome @getIncome="getIncome" :editIncome="modify" @close="close" v-if="modal"></addIncome>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import moment from 'moment'
import addIncome from '@/components/Incomes/income_modal.vue'

export default {
    name: 'incomePageZ',
    components: {
        // moment,
        addIncome
    },
    data() {
        return {
            result: '',
            moment: moment,
            modal: false,
            modify: false,
            success: false,
            incomeForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            },
            searchText: "",

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        editIncome(item) {
            this.modal = true
            this.modify = true
            this.$store.state.income = item
        },
        close() {
            this.modal = false
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getIncome(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getIncome(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getIncome(url = null) {
            if (!url) {
                url = '/income/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.incomes = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getIncome(`/income/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        search() {
            this.result = "recherche..."
            this.axios.get(`/income/?search=${this.searchText}`, this.getHeaders())
                .then((response) => {
                    this.$store.state.incomes = response.data.results
                    this.result = ""
                }).catch((error) => {
                    console.log(error)
                })
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'income.xlsx')
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.incomes, this.searchText);
        },
    },
    mounted() {
        this.getIncome();
    }
};
</script>

<style></style>