<template>
    <div class="statistics">

        <div class="card-1">
            <div class="card-body">
                <h1 class="card-title-1">Membres</h1>
                <i class="fas fa-list"></i>
                <h3>{{ this.$store.state.membersTot }}</h3>
                <div>
                    <p v-if="this.$store.state.new_members <= 0"></p>
                    <p v-else-if="this.$store.state.new_members === 1">{{ this.$store.state.new_members }} Membre en
                        processus d'intégration</p>
                    <p v-else>{{ this.$store.state.new_members }} Membres en processus d'intégration</p>
                </div>
            </div>
        </div>

        <div class="card-2">
            <div class="card-body">
                <h1 class="card-title-2">Familles</h1>
                <i class="fas fa-users"></i>
                <h3>{{ familyDetails.family }}</h3>
            </div>
        </div>

        <div class="card-3">
            <div class="card-body">
                <h1 class="card-title-3">Zones</h1>
                <i class="fas fa-users"></i>
                <h3>{{ zoneDetails.name }}</h3>
            </div>
        </div>

        <div class="card-4">
            <div class="card-body">
                <h1 class="card-title-4">District</h1>
                <i class="fas fa-users"></i>
                <h3>{{ districtDetails.name }}</h3>
            </div>
        </div>

    </div>

    <div class="parent-title">
        <div class="title">
            <h2>Les pourcentages des présences</h2>
            <div class="filter_pr">
                <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
                <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
                <button @click="getCellMemberAttendance()">Trier</button>
            </div>
        </div>
    </div>

    <div class="parent">
        <div class="card-pourcentage-1">
            <div class="card-body">
                <p class="card-pr">Présence</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.presence_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.presence_percentage }}%</div>
                </div>
            </div>
        </div>
        <div class="card-pourcentage-3">
            <div class="card-body">
                <p class="card-pr">Justifié</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.justify_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.justify_percentage }}%</div>
                </div>
            </div>
        </div>
        <div class="card-pourcentage-2">
            <div class="card-body">
                <p class="card-pr">Absence</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.absence_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.absence_percentage }}%</div>
                </div>
            </div>
        </div>
    </div>

    <div class="parent-title">
        <div class="title">
            <br>
            <h2>Les leaders</h2>
        </div>
    </div>

    <div class="parent">

        <div class="card-leader">
            <h3>Leader de la {{ districtDetails.name }}</h3>
            <hr>
            <p>Chef: {{ districtDetails.chief }}</p>
            <p>Phone 1: {{ districtDetails.chiefPhone1 }}</p>
            <p>Phone 2: {{ districtDetails.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ districtDetails.chiefVice }}</p>
            <p>Phone 1: {{ districtDetails.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ districtDetails.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ districtDetails.core1 }}</p>
            <p>Phone: {{ districtDetails.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ districtDetails.core2 }}</p>
            <p>Phone: {{ districtDetails.Phonecore2 }}</p>
        </div>

        <div class="card-leader">
            <h3>Leader de la {{ zoneDetails.name }}</h3>
            <hr>
            <p>Chef: {{ zoneDetails.chief }}</p>
            <p>Phone 1: {{ zoneDetails.chiefPhone1 }}</p>
            <p>Phone 2: {{ zoneDetails.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ zoneDetails.chiefVice }}</p>
            <p>Phone 1: {{ zoneDetails.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ zoneDetails.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ zoneDetails.core1 }}</p>
            <p>Phone: {{ zoneDetails.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ zoneDetails.core2 }}</p>
            <p>Phone: {{ zoneDetails.Phonecore2 }}</p>
        </div>

        <div class="card-leader">
            <h3>Leader de la famille {{ familyDetails.family }}</h3>
            <hr>
            <p>Chef: {{ familyDetails.chief }}</p>
            <p>Phone 1: {{ familyDetails.chiefPhone1 }}</p>
            <p>Phone 2: {{ familyDetails.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ familyDetails.chiefVice }}</p>
            <p>Phone 1: {{ familyDetails.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ familyDetails.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ familyDetails.core1 }}</p>
            <p>Phone: {{ familyDetails.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ familyDetails.core2 }}</p>
            <p>Phone: {{ familyDetails.Phonecore2 }}</p>
        </div>

        <div class="card-leader" v-for="item in this.$store.state.cell" :key="item.id">
            <h3>Leader du cellule {{ item.cell }}</h3>
            <hr>
            <p>Chef: {{ item.chief }}</p>
            <p>Phone 1: {{ item.chiefPhone1 }}</p>
            <p>Phone 2: {{ item.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ item.chiefVice }}</p>
            <p>Phone 1: {{ item.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ item.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ item.core1 }}</p>
            <p>Phone: {{ item.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ item.core2 }}</p>
            <p>Phone: {{ item.Phonecore2 }}</p>
            <hr>
            <h4>Hote du cellule</h4>
            <p>Nom: {{ item.cellHost_name }}</p>
            <p>Phone 1: {{ cellHostDetails.phone1 }}</p>
            <p>Phone 2: {{ cellHostDetails.phone2 }}</p>
            <p>Adresse: {{ item.cellHost_address }}</p>
        </div>

    </div>

    <br>
</template>

<script>
// import OurPhoto from '@/components/OurPhoto.vue'

export default {
    name: 'DashboardPage',
    components: {
        // OurPhoto
    },
    data() {
        return {
            modal: false,
            cell: {},
            cellHostDetails: '',
            familyDetails: '',
            zoneDetails: '',
            districtDetails: '',

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

            date_from: '',
            date_to: ''

        };
    },
    methods: {
        close() {
            this.modal = false
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.fetchMembers(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.fetchMembers(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        fetchMembers(url = null) {
            if (!url) {
                url = '/cell-members/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.members = response.data.results
                    this.$store.state.membersTot = response.data.count
                    this.$store.state.new_members = response.data.new_members
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.fetchMembers(`/cell-members/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        getCellLeader() {
            this.axios.get('/logged-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
                this.$store.state.cellOgi = this.$store.state.cell[0].cell
                this.getCellHostDetail();
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getCellHostDetail() {
            const cellHost_id = this.$store.state.cell[0].cellHost
            this.axios.get(`/cell-hosts/${cellHost_id}`, this.getHeaders())
                .then(response => {
                    this.cellHostDetails = response.data;
                    this.getfamilyDetail();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getfamilyDetail() {
            const family_id = this.$store.state.cell[0].family
            this.axios.get(`/family/${family_id}`, this.getHeaders())
                .then(response => {
                    this.familyDetails = response.data;
                    this.getZoneDetail();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getZoneDetail() {
            const zone_id = this.familyDetails.zone
            this.axios.get(`/zone/${zone_id}`, this.getHeaders())
                .then(response => {
                    this.zoneDetails = response.data;
                    this.getDistrictDetail();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getDistrictDetail() {
            const district_id = this.zoneDetails.district
            this.axios.get(`/district/${district_id}`, this.getHeaders())
                .then(response => {
                    this.districtDetails = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCellMemberAttendance(url = null) {
            if (!url) {
                url = `/cell-member-attendance/?date_range=${this.date_from},${this.date_to}`;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.memberAttendance = response.data;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchMembers();
        this.getCellMemberAttendance();
        this.getCellLeader();
    },
};
</script>

<style></style>
