<template>
    <div class="content-ogi">

        <div class="statistics">
            <div class="card-1">
                <div class="card-body">
                    <h1 class="card-title-1" @click="showMembers">Membres</h1>
                    <i class="fas fa-list"></i>
                    <h3>{{ this.$store.state.members.count }}</h3>
                    <div>
                        <p v-if="this.$store.state.members.new_members <= 0"></p>
                        <p v-else-if="this.$store.state.members.new_members === 1">{{
                        this.$store.state.members.new_members
                    }} Membre en
                            processus d'intégration</p>
                        <p v-else>{{ this.$store.state.members.new_members }} Membres en processus d'intégration</p>
                    </div>
                </div>
            </div>

            <div class="card-2">
                <div class="card-body">
                    <h1 class="card-title-2" @click="showCells">Cellules</h1>
                    <i class="fas fa-users"></i>
                    <h3>{{ this.$store.state.cell.length }}</h3>
                </div>
            </div>

            <div class="card-3">
                <div class="card-body">
                    <h1 class="card-title-3" @click="showFamilies">Familles</h1>
                    <i class="fas fa-users"></i>
                    <h3>{{ $store.state.family.length }}</h3>
                </div>
            </div>

            <div class="card-4">
                <div class="card-body">
                    <h1 class="card-title-4" @click="showZones">Zones</h1>
                    <i class="fas fa-users"></i>
                    <h3>{{ $store.state.zoneTot }}</h3>
                </div>
            </div>

            <div class="card-5">
                <div class="card-body">
                    <h1 class="card-title-5" @click="showDistricts">District</h1>
                    <i class="fas fa-users"></i>
                    <h3>{{ $store.state.districtTot }}</h3>
                </div>
            </div>

            <div class="card-6">
                <div class="card-body">
                    <h1 class="card-title-6" @click="showDepartments">Departements</h1>
                    <i class="fas fa-list"></i>
                    <h3>{{ $store.state.departmentTot }}</h3>
                </div>
            </div>
        </div>

        <div class="parent-title">
            <div class="title">
                <h2>Pourcentages des Présences dans les cellules</h2>
                <div class="filter_pr">
                    <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
                    <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
                    <button @click="getAllAttendance()">Trier</button>
                </div>
            </div>
        </div>

        <div class="parent">
            <div class="card-pourcentage-1">
                <div class="card-body">
                    <p class="card-pr">Présence</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.presence_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.presence_percentage }}%</div>
                    </div>
                </div>
            </div>
            <div class="card-pourcentage-3">
                <div class="card-body">
                    <p class="card-pr">Justifié</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.justify_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.justify_percentage }}%</div>
                    </div>
                </div>
            </div>
            <div class="card-pourcentage-2">
                <div class="card-body">
                    <p class="card-pr">Absence</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.absence_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.absence_percentage }}%</div>
                    </div>
                </div>
            </div>
        </div>

        <br>
    </div>
</template>

<script>

export default {
    name: 'DashboardPageZ',
    components: {

    },
    data() {
        return {
        };
    },
    methods: {
        showMembers() {
            this.$router.push('/dashboard/staff-member')
        },
        showCells(){
            this.$router.push('/dashboard/staff-cell')
        },
        showFamilies(){
            this.$router.push('/dashboard/staff-family')
        },
        showZones(){
            this.$router.push('/dashboard/staff-zone')
        },
        showDistricts(){
            this.$router.push('/dashboard/staff-district')
        },
        showDepartments(){
            this.$router.push('/dashboard/staff-department')
        },
        fetchMembers(url = null) {
            if (!url) {
                url = '/members/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.members = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getCellLeader() {
            this.axios.get('/cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getFamilyLeader() {
            this.axios.get('/family/', this.getHeaders()
            ).then(response => {
                this.$store.state.family = response.data;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getZoneLeader() {
            this.axios.get('/zone/', this.getHeaders()
            ).then(response => {
                this.$store.state.zone = response.data.results;
                this.$store.state.zoneTot = response.data.count
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getDistrictLeader() {
            this.axios.get('/district/', this.getHeaders()
            ).then(response => {
                this.$store.state.district = response.data.results;
                this.$store.state.districtTot = response.data.count
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getDepartmentLeader() {
            this.axios.get('/department/', this.getHeaders()
            ).then(response => {
                this.$store.state.departments = response.data.results;
                this.$store.state.departmentTot = response.data.count
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getAllAttendance(url = null) {
            if (!url) {
                url = `/all-member-attendance/?date_range=${this.date_from},${this.date_to}`;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.memberAttendance = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchMembers();
        this.getCellLeader()
        this.getFamilyLeader();
        this.getZoneLeader();
        this.getDistrictLeader();
        this.getDepartmentLeader();
        this.getAllAttendance();
    },
};
</script>

<style></style>