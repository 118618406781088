<template>
    <div class="parent-table">
        <h3>Les cellules</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText">
                <button class="btn-action">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cellule</th>
                        <th>Hôte de Cellule</th>
                        <th>Famille</th>
                        <th>chef</th>
                        <th>chef Phone</th>
                        <th>Vice-chef</th>
                        <th>Vice-chef Phone</th>
                        <th>Noyau 1</th>
                        <th>Phone Noyau 1</th>
                        <th>Noyau 2</th>
                        <th>Phone Noyau 2</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.cell }}</td>
                        <td>{{ item.cellHost_name }}</td>
                        <td>{{ item.family_name }}</td>
                        <td>{{ item.chief }}</td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.chiefPhone1">{{ item.chiefPhone1 }}</a></span>
                            <span><a :href="'tel:' + item.chiefPhone2">{{ item.chiefPhone2 }}</a></span>
                        </td>

                        <td>{{ item.chiefVice }}</td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.chiefVicePhone1">{{ item.chiefVicePhone1 }}</a></span>
                            <span><a :href="'tel:' + item.chiefVicePhone2">{{ item.chiefVicePhone2 }}</a></span>
                        </td>

                        <td>{{ item.core1 }}</td>
                        <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore1">{{ item.Phonecore1 }}</a></span>
                        </td>
                        <td>{{ item.core2 }}</td>
                        <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore2">{{ item.Phonecore2 }}</a></span>
                        </td>
                        <td>
                            <button class="btn-action" @click="editCell(item)"> <i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <cell_modal v-if="modal" @getCells="getCells" @close="close" :editCell="modify" @editCell="editCell">
        </cell_modal>

    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import cell_modal from '@/components/Cells/cell_modal.vue'

export default {
    name: 'cellPageS',
    components: {
        // moment,
        // OurSuccess
        cell_modal
    },
    data() {
        return {
            result: '',
            modal: false,
            modify: false,
            success: false,
            searchText: "",
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editCell(item) {
            this.modal = true
            this.modify = true
            this.$store.state.cellOgi = item
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'cell.xlsx')
        },
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.cell, this.searchText);
        },
    },
    mounted() {
    }
};
</script>

<style></style>