<template>
  <div class="container-modal">
    <div class="content-modal">
      <div class="title-modal">
        <h5>Communication</h5>
        <button class="backBash" @click="goDashboard">&times;</button>
      </div>
      <div class="content">
        <form class="form">
          <label for="lastName" class="placeholder">Envoyer par</label>
          <input id="lastName" v-model="communicationForm.sender" type="text" required placeholder=" " />
          <label for="lastName" class="placeholder">Communiqué:</label>
          <textarea id="lastName" v-model="communicationForm.notification" type="text" placeholder=" " rows="5"
            cols="70"></textarea>
        </form>
      </div>
      <div class="buttons">
        <button @click="sendCommunication" type="submit">{{ loading ? "Chargement..." : btn }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunicationPage",
  components: {
    // moment,
  },
  data() {
    return {
      communicationForm: {
        sender: "",
        notification: "",
        date: new Date().toISOString().substr(0, 10),
      },
      btn: "Envoyer",
      loading: false,
    };
  },
  methods: {
    goDashboard() {
      this.$router.push('/dashboard/district');
    },
    sendCommunication() {
      this.loading = true;

      this.axios
        .post("/communication/", this.communicationForm, this.getHeaders())
        .then((response) => {
          console.log(response);

          setTimeout(() => {
            this.loading = false;
          }, 2500);
          this.$toast.success(`Envoyé avec succès`, {
            position: "bottom",
            maxToasts: 1
          })
          this.communicationForm = {}
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
          this.loading = false;
        });
    },
  },
  // mounted(){

  // }
};
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>