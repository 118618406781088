<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Membre</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <img :src="photo" class="photo-member" />
                <h5>Nom: <span class="member-detail">{{ lastName }}</span></h5>
                <h5>Prénom: <span class="member-detail">{{ firstName }}</span></h5>
                <h5>Téléphone 1: <span class="member-detail">{{ phone1 }}</span></h5>
                <h5>Téléphone 2: <span class="member-detail">{{ phone2 }}</span></h5>
                <h5>Adresse: <span class="member-detail">{{ address }}</span></h5>
                <h5>Cellule: <span class="member-detail">{{ cell?.cell }}</span></h5>

                <h5 v-if="gender === 'Female'">Genre: <span style="font-weight: 500;">Femme</span></h5>
                <h5 v-else>Sexe: <span style="font-weight: 500;">Homme</span></h5>

                <h5>Etat civil: <span class="member-detail">{{ civilStatus }}</span></h5>
                <h5>Promotion: <span class="member-detail">{{ period_name }}</span></h5>

                <h5 v-if="baptised == true">Baptisé(e): <span style="font-weight: 500;">Oui</span></h5>
                <h5 v-else>Baptisé(e): <span style="font-weight: 500;">Non</span></h5>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: ['showMember'],
    components: {
    },
    data() {
        return {
            id: '',
            firstName: "",
            lastName: "",
            phone1: "",
            phone2: "",
            address: "",
            gender: "Male",
            civilStatus: 'Single',
            photo: null,
            bornAgainShortStoryAndWhenWhere: "",
            baptised: false,
            baptemChurchDate: "",
            recentChurchs: "",
            whyYouChangeChurch: "",
            haveYouSayGoodbyeToRecentChurch: "",
            whatDoYouWantChurchToDoForYou: "",
            wantToTalkWithLeader: false,
            talk: false,
            talkWith: "",
            now_member: false,
            active: true,
            reason_for_departure: "",
            cell: '',
            period_name: null,

        };
    },
    methods: {
        close() {
            this.$emit('close')
        },
        fetchMembers() {
            this.$emit('fetchMembers')
        },
    },

    mounted() {
        this.fetchMembers()

        if (this.showMember) {

            this.id = this.$store.state.members.id
            this.firstName = this.$store.state.members.firstName
            this.lastName = this.$store.state.members.lastName
            this.phone1 = this.$store.state.members.phone1
            this.phone2 = this.$store.state.members.phone2
            this.address = this.$store.state.members.address
            this.civilStatus = this.$store.state.members.civilStatus
            this.gender = this.$store.state.members.gender
            this.photo = this.$store.state.members.photo
            this.bornAgainShortStoryAndWhenWhere = this.$store.state.members.bornAgainShortStoryAndWhenWhere
            this.baptised = this.$store.state.members.baptised
            this.baptemChurchDate = this.$store.state.members.baptemChurchDate
            this.recentChurchs = this.$store.state.members.recentChurchs
            this.whyYouChangeChurch = this.$store.state.members.whyYouChangeChurch
            this.haveYouSayGoodbyeToRecentChurch = this.$store.state.members.haveYouSayGoodbyeToRecentChurch
            this.whatDoYouWantChurchToDoForYou = this.$store.state.members.whatDoYouWantChurchToDoForYou
            this.wantToTalkWithLeader = this.$store.state.members.wantToTalkWithLeader
            this.talk = this.$store.state.members.talk
            this.talkWith = this.$store.state.members.talkWith
            this.now_member = this.$store.state.members.now_member
            this.active = this.$store.state.members.active
            this.reason_for_departure = this.$store.state.members.reason_for_departure
            this.cell = this.$store.state.members.cell
            this.period_name = this.$store.state.members.period_name

        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access,
                    "Content-Type": "multipart/form-data" // Set the content type manually
                }
            }
        }
    }
};
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>
