<template>
    <div class="parent-table">
        <h3>Les Membres</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText" @keydown.enter="search">
                <button class="btn-action" @click="search">
                    <i class="fas fa-search"></i>
                </button>
                <p>{{ result }}</p>
            </div>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Nom et Prénom</th>
                        <th>Want To Talk With Leader?</th>
                        <th>Talk</th>
                        <th>Talk with</th>
                        <th>Phone</th>
                        <th>Cellule</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>
                            <img :src="item.photo" class="t-photo" />
                        </td>

                        <td :class="{ 't-member': item.now_member, 't-no-member': !item.now_member }">
                            {{ item.lastName }} {{ item.firstName }}
                        </td>

                        <td v-if="item.wantToTalkWithLeader === true">Oui</td>
                        <td v-else>Non</td>
                        <td v-if="item.talk === true">Oui</td>
                        <td v-else>Non</td>
                        <td>{{ item.talkWith }}</td>

                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.phone1">{{ item.phone1 }}</a></span>
                            <span><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a></span>
                        </td>

                        <td>
                            <span v-if="item.cell.cell != ''">{{ item.cell.cell }}</span>
                            <span v-else style="color: red;">Null</span>
                        </td>
                        <td class="action">
                            <button class="btn-action" @click="editMember(item)">
                                <i class="fas fa-edit"></i>
                            </button>
                            <button class="btn-action" @click="showMember(item)">
                                <i class="fas fa-eye"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>

                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>NB: Membres avec la couleur verte sont en processus d'intégration </p>
        <div class="pagination">
            <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
            <button v-for="pageNumber in displayedPages" :key="pageNumber" @click="fetchMembers(pageNumber)"
                :class="{ active: currentPage === pageNumber }">{{
                    pageNumber }}</button>
            <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
            <button v-if="!displayedPages.includes(totalPages)" @click="fetchMembers(totalPages)">
                {{ totalPages }}
            </button>
            <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
        </div>
        <member_modal v-if="modal" @close="close" @fetchMembers="fetchMembers" :editMember="modify"></member_modal>
        <M_detail_modal v-if="showModal" @close="close" @fetchMembers="fetchMembers" :showMember="show">
        </M_detail_modal>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import member_modal from '@/components/Member/member_modal.vue'
import M_detail_modal from '@/components/Member/M_detail_modal.vue'

export default {
    name: 'MemberPageS',
    components: {
        // OurPhoto,
        member_modal,
        M_detail_modal
    },
    data() {
        return {
            result: '',

            modal: false,
            modify: false,

            show: false,
            showModal: false,

            searchText: "",

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

        };
    },
    methods: {
        close() {
            this.modal = false
            this.showModal = false
        },
        editMember(item) {
            this.modify = true
            this.modal = true
            this.showModal = false
            this.$store.state.members = item
        },
        showMember(item) {
            this.show = true
            this.modal = false
            this.showModal = true
            this.$store.state.members = item
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.fetchMembers(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.fetchMembers(this.currentPage); // Pass current page number
            }
        },
        // fetchMembers(url = null) {
        //     if (!url) {
        //         url = '/members/';
        //     }
        //     this.axios.get(url, this.getHeaders())
        //         .then(response => {
        //             // Update member attendance data
        //             this.$store.state.members = response.data.results
        //             this.$store.state.membersTot = response.data.count
        //             this.$store.state.new_members = response.data.new_members
        //             // Update pagination variables
        //             this.previousUrl = response.data.previous;
        //             this.nextUrl = response.data.next;
        //             this.totalPages = Math.ceil(response.data.count / this.pageSize);
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        fetchMembers(pageNumber = null) {
            let url = `/members/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.members = response.data.results
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        search() {
            this.result = "recherche..."
            this.axios.get(`/members/?search=${this.searchText}`, this.getHeaders())
                .then((response) => {
                    this.$store.state.members = response.data.results
                    this.result = ""
                }).catch((error) => {
                    console.log(error)
                })
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'member.xlsx')
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.members, this.searchText);
        },
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        }
    },
    mounted() {
        this.fetchMembers();
    }
};
</script>

<style></style>