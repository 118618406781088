<template>

    <div class="statistics">

        <div class="card-1">
            <div class="card-body">
                <h1 class="card-title-1">Membres</h1>
                <i class="fas fa-list"></i>
                <h3>{{ this.$store.state.members.count }}</h3>
                <div>
                    <p v-if="this.$store.state.members.new_members <= 0"></p>
                    <p v-else-if="this.$store.state.members.new_members === 1">{{ this.$store.state.members.new_members }} Membre en
                        processus d'intégration</p>
                    <p v-else>{{ this.$store.state.members.new_members }} Membres en processus d'intégration</p>
                </div>
            </div>
        </div>

        <div class="card-2">
            <div class="card-body">
                <h1 class="card-title-2">Cellules</h1>
                <i class="fas fa-users"></i>
                <h3>{{ this.$store.state.cellTot }}</h3>
            </div>
        </div>

        <div class="card-3">
            <div class="card-body">
                <h1 class="card-title-3">Zones</h1>
                <i class="fas fa-users"></i>
                <h3>{{ zoneDetails.name }}</h3>
            </div>
        </div>

        <div class="card-4">
            <div class="card-body">
                <h1 class="card-title-4">District</h1>
                <i class="fas fa-users"></i>
                <h3>{{ districtDetails.name }}</h3>
            </div>
        </div>

    </div>

    <div class="parent-title">
        <div class="title">
            <h2>Les pourcentages des présences</h2>
            <div class="filter_pr">
                <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
                <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
                <button @click="getFamilyMemberAttendance()">Trier</button>
            </div>
        </div>
    </div>

    <div class="parent">
        <div class="card-pourcentage-1">
            <div class="card-body">
                <p class="card-pr">Présence</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.presence_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.presence_percentage }}%</div>
                </div>
            </div>
        </div>
        <div class="card-pourcentage-3">
            <div class="card-body">
                <p class="card-pr">Justifié</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.justify_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.justify_percentage }}%</div>
                </div>
            </div>
        </div>
        <div class="card-pourcentage-2">
            <div class="card-body">
                <p class="card-pr">Absence</p>
                <div class="card_total">
                    <div>Total: {{ this.$store.state.memberAttendance.absence_total }}</div>
                    <div>|</div>
                    <div>{{ this.$store.state.memberAttendance.absence_percentage }}%</div>
                </div>
            </div>
        </div>
    </div>

    <div class="parent-table">
        <div class="filter_pr">
        <h3>Les Membres de la famille {{ $store.state.familyOgi }}</h3>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Nom et Prenom</th>
                        <th>Phone</th>
                        <th>Cellule</th>
                        <th>Adresse</th>
                        <th>Sexe</th>
                        <th>Promotion</th>
                        <th>Baptisé(e)</th>
                        <th>Etat Civil</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.members.results" :key="item.id">
                        <td>
                            <img :src="item.photo" @click="modal = true" class="t-photo" />
                            <OurPhoto :src="item.photo" @close="close" v-if="modal"></OurPhoto>
                        </td>

                        <td :class="{ 't-member': item.now_member, 't-no-member': !item.now_member }">
                            {{ item.lastName }} {{ item.firstName }}
                        </td>

                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.phone1">{{ item.phone1 }}</a></span>
                            <span><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a></span>
                        </td>

                        <td>{{ item.cell.cell }}</td>
                        <td>{{ item.address }}</td>
                        <td v-if="item.gender === 'Female'"> Femme</td>
                        <td v-else>Homme</td>
                        <td>{{ item.period_name }}</td>
                        <td v-if="item.baptised == true"> Oui</td>
                        <td v-else>Non</td>
                        <td>{{ item.civilStatus }}</td>

                    </tr>
                </tbody>
            </table>
        </div>
        <p>NB: Membres avec la couleur verte sont en processus d'integration </p>
        <div class="pagination">
            <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
            <button v-for="pageNumber in displayedPages" :key="pageNumber" @click="fetchMembers(pageNumber)"
                :class="{ active: currentPage === pageNumber }">{{
                    pageNumber }}</button>
            <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
            <button v-if="!displayedPages.includes(totalPages)" @click="fetchMembers(totalPages)">
                {{ totalPages }}
            </button>
            <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
        </div>
    </div>


    <div class="parent-title">
        <div class="title">
            <h2>Les cellules de la famille {{ $store.state.familyOgi }}</h2>
        </div>
    </div>

    <div class="parent">
        <div class="card-leader" v-for="item in this.$store.state.cell" :key="item.id">
            <h3>Leader de la Cellule {{ item.cell }}</h3>
            <hr>
            <p>Chef: {{ item.chief }}</p>
            <p>Phone 1: {{ item.chiefPhone1 }}</p>
            <p>Phone 2: {{ item.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ item.chiefVice }}</p>
            <p>Phone 1: {{ item.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ item.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ item.core1 }}</p>
            <p>Phone: {{ item.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ item.core2 }}</p>
            <p>Phone: {{ item.Phonecore2 }}</p>
            <hr>
            <h4>Hote de la cellule</h4>
            <p>Nom: {{ item.cellHost_name }}</p>
            <p>Phone 1: {{ item.cellHost_phone1 }}</p>
            <p>Phone 2: {{ item.cellHost_phone2 }}</p>
            <p>Adresse: {{ item.cellHost_address }}</p>
        </div>
        <p class="alertMulti" v-if="this.$store.state.cellTot > 1">Les Cellules sont superieur à 12, il faut faire la
            multiplication.</p>

    </div>

    <div class="parent-title">
        <div class="title">
            <h2>Les Leaders</h2>
        </div>
    </div>

    <div class="parent">
        <div class="card-leader" v-for="item in this.$store.state.family" :key="item.id">
            <h3>Leader de la Famille {{ item.family }}</h3>
            <p>Chef: {{ item.chief }}</p>
            <p>Phone 1: {{ item.chiefPhone1 }}</p>
            <p>Phone 2: {{ item.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ item.chiefVice }}</p>
            <p>Phone 1: {{ item.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ item.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ item.core1 }}</p>
            <p>Phone: {{ item.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ item.core2 }}</p>
            <p>Phone:{{ item.Phonecore2 }}</p>
        </div>

        <div class="card-leader">
            <h3>Leader de la {{ zoneDetails.name }}</h3>
            <p>Chef: {{ zoneDetails.chief }}</p>
            <p>Phone 1: {{ zoneDetails.chiefPhone1 }}</p>
            <p>Phone 2: {{ zoneDetails.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ zoneDetails.chiefVice }}</p>
            <p>Phone 1: {{ zoneDetails.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ zoneDetails.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ zoneDetails.core1 }}</p>
            <p>Phone: {{ zoneDetails.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ zoneDetails.core2 }}</p>
            <p>Phone:{{ zoneDetails.Phonecore2 }}</p>
        </div>

        <div class="card-leader">
            <h3>Leader du {{ districtDetails.name }}</h3>
            <p>Chef: {{ districtDetails.chief }}</p>
            <p>Phone 1: {{ districtDetails.chiefPhone1 }}</p>
            <p>Phone 2: {{ districtDetails.chiefPhone2 }}</p>
            <hr>
            <p>Vice chef: {{ districtDetails.chiefVice }}</p>
            <p>Phone 1: {{ districtDetails.chiefVicePhone1 }}</p>
            <p>Phone 2: {{ districtDetails.chiefVicePhone2 }}</p>
            <hr>
            <p>Noyau 1: {{ districtDetails.core1 }}</p>
            <p>Phone: {{ districtDetails.Phonecore1 }}</p>
            <hr>
            <p>Noyau 2: {{ districtDetails.core2 }}</p>
            <p>Phone:{{ districtDetails.Phonecore2 }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DashboardPage',
    components: {
    },
    data() {
        return {
            modal: false,
            familyOgi: [],
            clickPhotoChang: false,

            zoneDetails: [],
            districtDetails: [],

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

            date_from: '',
            date_to: ''

        };
    },
    methods: {
        close() {
            this.modal = false
        },
        getFamilyMemberAttendance(url = null) {
            if (!url) {
                url = `/family-member-attendance/?date_range=${this.date_from},${this.date_to}`;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.memberAttendance = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getFamilyLeader() {
            this.axios.get('/logged-family/', this.getHeaders()
            ).then(response => {
                this.$store.state.family = response.data.results;
                this.$store.state.familyOgi = this.$store.state.family[0].family
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getFamilyCellLeader() {
            this.axios.get('/family-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
                this.$store.state.cellTot = response.data.count;
                this.getZoneDetail();
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getZoneDetail() {
            const zone_id = this.$store.state.family[0].zone
            this.axios.get(`/zone/${zone_id}`, this.getHeaders())
                .then(response => {
                    this.zoneDetails = response.data;
                    this.getDistrictDetail();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getDistrictDetail() {
            const district_id = this.zoneDetails.district
            this.axios.get(`/district/${district_id}`, this.getHeaders())
                .then(response => {
                    this.districtDetails = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.fetchMembers(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.fetchMembers(this.currentPage); // Pass current page number
            }
        },
        fetchMembers(pageNumber = null) {
            let url = `/family-members/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.members = response.data
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
    },
    mounted() {
        this.fetchMembers();
        this.getFamilyMemberAttendance();
        this.getFamilyLeader();
        this.getFamilyCellLeader();
    },
};
</script>

<style></style>