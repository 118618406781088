<template>
    <div class="parent-table">
        <h3>Les rapports des familles</h3>
        <div class="header-table">
            <input type="text" placeholder="rechercher..." v-model="searchText">
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <button @click=searchPage(searchQuery)>Appliquer</button>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Famille</th>
                        <th>Cellule</th>
                        <th>Accompagnement</th>
                        <th>Responsable</th>
                        <th>Thème développée</th>
                        <th>Observation</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody v-if="filteredReppoFamily.length > 0">
                    <tr v-for="item in filteredReppoFamily" :key="item.id">
                        <td>{{ item.family_name }}</td>
                        <td>{{ item.cell_name }}</td>

                        <td v-if="item.Followup === 'phone_Followup'">Par téléphone</td>
                        <td v-else-if="item.Followup === 'physical_Followup'">Physique</td>
                        <td v-else>no</td>

                        <td>{{ item.responsible }}</td>
                        <td>{{ item.topic }}</td>
                        <td>{{ item.observation }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="parent-table">
        <h3>Les particularites dans les observations des familles</h3>
        <div class="header-table">
            <input type="text" placeholder="rechercher..." v-model="searchTextParticula">
            <input type="text" placeholder="cherche la page" v-model="searchQueryParticula">
            <button @click=searchPageParticula(searchQueryParticula)>Appliquer</button>
            <p>{{ errorMessageParticula }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Family</th>
                        <th>Commentaire</th>
                        <th>Evénement après Mardi</th>
                        <th>Suggestion & ulgence</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody v-if="filteredReppoFamilyParticula.length > 0">
                    <tr v-for="item in filteredReppoFamilyParticula" :key="item.id">
                        <td>{{ item.family_name }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.after_cell_event }}</td>
                        <td>{{ item.suggestion_or_emergency }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,
            selectedDate: new Date().toISOString().substr(0, 10),

            searchText: "",

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

            searchTextParticula: "",
            errorMessageParticula: '',
            previousUrlParticula: null,
            nextUrlParticula: null,
            currentPageParticula: 1,
            totalPagesParticula: 1,
            pageSizeParticula: 40,
            searchQueryParticula: 1,
        }
    },
    components: {
        // moment,
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getFamilyReport(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getFamilyReport(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getFamilyReport(url = null) {
            if (!url) {
                url = '/family-report/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.familyReport = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getFamilyReport(`/family-report/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        incrementPageParticula() {
            if (this.nextUrlParticula) {
                this.currentPageParticula++;
                this.getFamilyObservationParticularity(this.nextUrlParticula);
                this.searchQueryParticula = this.currentPageParticula;
            }
        },
        decrementPageParticula() {
            if (this.previousUrlParticula) {
                this.currentPageParticula--;
                this.getFamilyObservationParticularity(this.previousUrlParticula);
                this.searchQueryParticula = this.currentPageParticula;
            }
        },
        getFamilyObservationParticularity(url = null) {
            if (!url) {
                url = '/family-observation-particularity/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.familyObservationParticularity = response.data.results
                    // Update pagination variables
                    this.previousUrlParticula = response.data.previous;
                    this.nextUrlParticula = response.data.next;
                    this.totalPagesParticula = Math.ceil(response.data.count / this.pageSizeParticula);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPageParticula(searchQueryParticula) {
            const pageNumberParticula = parseInt(searchQueryParticula);
            if (pageNumberParticula >= 1 && pageNumberParticula <= this.totalPagesParticula) {
                this.currentPageParticula = pageNumberParticula;
                this.getFamilyObservationParticularity(`/family-observation-particularity/?page=${pageNumberParticula}`);
            } else {
                this.errorMessageParticula = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessageParticula = null;
                }, 2500);
            }
        },
    },
    computed: {
        filteredReppoFamily() {
            return this.searchInArray(this.$store.state.familyReport, this.searchText);
        },
        filteredReppoFamilyParticula() {
            return this.searchInArray(this.$store.state.familyObservationParticularity, this.searchTextParticula,
            );
        }
    },
    mounted() {
        this.getFamilyReport();
        this.getFamilyObservationParticularity();
    },
}
</script>

<style></style>