<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Rapport de la District</h5>
                <button class="backBash" @click="goDashboard">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <input class="dateCenter" type="date" v-model="selectedDate">
                    <div class="card-container">
                        <div class="card" v-for="zone in this.$store.state.zone" :key="zone.id">
                            <div class="card-header">
                                {{ zone.name }}
                            </div>
                            <label class="card-title">Accompagnement</label>
                            <div class="card-label">
                                <input type="radio" :id="'phone_Followup_' + zone.id" value="phone_Followup"
                                    v-model="zone.Followup">
                                <label :for="'phone_Followup_' + zone.id">Par téléphone</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'physical_Followup_' + zone.id" value="physical_Followup"
                                    v-model="zone.Followup">
                                <label :for="'physical_Followup_' + zone.id">Physique</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'no_' + zone.id" value="no" v-model="zone.Followup">
                                <label :for="'no_' + zone.id">No</label>
                            </div>
                            <div class="card-inputs">
                                <input type="text" placeholder="Responsable" v-model="zone.responsible" required>
                                <input type="text" placeholder="thème" v-model="zone.topic" required>
                                <input type="text" placeholder="Observation" v-model="zone.observation" required>
                            </div>
                        </div>
                    </div>
                    <label class="placeholder">Commentaire:</label>
                    <input type="text" placeholder="Commentaire (defis, success...)" v-model="reportForm.comment"
                        required>
                    <small v-for="err in data_error?.comment" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Evénements après Mardi:</label>
                    <input type="text" placeholder="Evénements après Mardi" v-model="reportForm.after_cell_event"
                        required>
                    <label class="placeholder">Suggestions ou Urgence:</label>
                    <input type="text" placeholder="Suggestions ou Urgence" v-model="reportForm.suggestion_or_emergency"
                        required>
                </form>
            </div>
            <div class="buttons">
                <button @click="onSubmit" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>

export default {

    data() {
        return {
            selectedDate: new Date().toISOString().substr(0, 10),
            reportForm: {
                id: 1,
                comment: '',
                after_cell_event: '',
                suggestion_or_emergency: '',
            },
            errorMessage: '',
            btn: 'Envoyer',
            loading: false
        }
    },
    components: {
    },
    methods: {
        goDashboard() {
            this.$router.push('/dashboard/district');
        },
        getDistrictLeader() {
            this.axios.get('/logged-district/', this.getHeaders()
            ).then(response => {
                this.$store.state.district = response.data.results;
                this.$store.state.districtOgi = this.$store.state.district[0].name
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getDistrictZoneLeader() {
            this.axios.get('/district-zone/', this.getHeaders()
            ).then(response => {
                this.$store.state.zone = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        onSubmit() {
            this.loading = true

            const district_id = this.$store.state.district[0].id

            const observaParticulaForm = {
                id: 1,
                comment: this.reportForm.comment,
                after_cell_event: this.reportForm.after_cell_event,
                suggestion_or_emergency: this.reportForm.suggestion_or_emergency,
                date: this.selectedDate,
                district: district_id,
            };

            this.axios.post('/district-observation-particularity/', observaParticulaForm, this.getHeaders())
                .then(response => {

                    console.log(response);

                    this.$store.state.zone.forEach(zone => {
                        const districtReportData = {
                            zone: zone.id,
                            Followup: zone.Followup,
                            responsible: zone.responsible,
                            topic: zone.topic,
                            observation: zone.observation,
                            date: this.selectedDate,
                            district: this.$store.state.district[0].id,
                        };

                        this.axios.post('/district-report/', districtReportData, this.getHeaders())
                            .then(response => {
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                                this.errorMessage = 'Please Selectionnez !!'
                                this.$toast.error(error)
                                this.loading = false;
                            });
                    });
                    this.goDashboard()
                    this.loading = false;
                    this.$toast.success(`Envoyé avec succès`, {
                        position: "bottom",
                        maxToasts: 1
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.$toast.error(error, {
                        position: "bottom",
                        maxToasts: 1
                    })
                });
        },
    },
    mounted() {
        this.getDistrictLeader();
        this.getDistrictZoneLeader();
    },
}
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>
