<template>
    <div class="parent-table">
        <h3>Les utilisateurs</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText">
                <button class="btn-action">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nom d'utilisateur</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.role }}</td>
                        <td>
                            <button class="btn-action" @click="editUser(item)"> <i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <user_modal v-if="modal" @close="close" @getUser="getUser" :editUser="modify"></user_modal>
    </div>
</template>

<script>
import user_modal from '@/components/User/user_modal.vue'

export default {
    name: 'UserPageZ',
    components: {
        // moment,
        user_modal
    },
    data() {
        return {
            modal: false,
            modify: false,

            searchText: '',
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editUser(item) {
            this.modify = true
            this.modal = true
            this.$store.state.users = item
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.users, this.searchText);
        },
    },
    mounted() {
    }
};
</script>

<style></style>