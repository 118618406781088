<template>
    <NavBar />
    <div class="articles-container">
<!-- 
        <div v-for="(item, index) in this.$store.state.articles_categories" :key="index">
            <span class="slide-btn">{{ item.name }}</span>
        </div> -->

        <div class="articles">
            <div class="article-card" v-for="(item, index) in this.$store.state.articles" :key="index">
                <img :src="`${item.poster}`" loading="lazy" alt="..." />
                <div class="article-items">
                    <router-link class="link" :to="`/blog/article/${item.id}`">
                        <span class="article-header1">{{ cutText(item.title) }}</span>
                    </router-link>
                    <span class="article-content">{{ cutText(item.content) }}</span>
                    <span class="article-category">{{ item.category_name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    data() {
        return {
            
        }
    },
    components: {
        NavBar,
    },
    methods: {
        getArticles() {
            this.axios.get('articles/all-articles/').then(
                response => {
                    console.log(response)
                    this.$store.state.articles = response?.data?.results;
                }).catch(error => {
                    console.log(error)
                })
        },
        getCategories() {
            this.axios.get('categories/').then(
                response => {
                    console.log(response)
                    this.$store.state.articles_categories = response?.data?.results;
                }).catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getArticles();
        // this.getCategories();
    }
}

</script>
