<template>
    <div class="parent-table">
        <h3>Les objectives</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Objective</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.objectives" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.objective }}</td>
                        <td>
                            <button class="btn-action" @click="editObjective(item)"> <i
                                    class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <objective_modal v-if="modal" @close="close" :editObjective="modify" @getObjective="getObjective">
        </objective_modal>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import objective_modal from '@/components/Objectives/objective_modal.vue'

export default {
    name: 'objectivePageZ',
    components: {
        // moment,
        // OurSuccess
        objective_modal
    },
    data() {
        return {
            modal: false,
            modify: false,
            success: false,
            objectiveForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            }
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editObjective(item) {
            this.modal = true
            this.modify = true
            this.$store.state.objective = item
        },
        getObjective() {
            this.axios.get('/objective/', this.getHeaders()
            ).then(response => {
                this.$store.state.objectives = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.$store.state.objectives)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'objective.xlsx')
        }
    },
    mounted() {
        this.getObjective();
    }
};
</script>

<style></style>