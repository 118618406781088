<template>
    <header>
      <nav>
        <div class="brand">
          <img src="/static/logo.png" loading="lazy" />
        </div>
        <ul class="navigation">
          <li><router-link class="link" :to="{ name: 'home' }">Accueil</router-link></li>
          <li><router-link class="link" :to="{ name: 'articles' }">Blog</router-link></li>
        </ul>
      </nav>
    </header>
  </template>

<script>

</script>

<style>
header {
    background-color: var(--white);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition: 0.5s ease all;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

nav {
    display: flex;
    width: 100%;
    margin: 0px;
    justify-content: space-around;
}

.brand {
    width: 100px;
    display: flex;
    align-items: center;
}

.brand img {
    width: 50px;
    object-fit: cover;
}

.navigation {
    display: flex;
    justify-content: end;
    gap: 20px;
    padding-right: 20px;

}

ul,
.link {
    color: var(--green);
    list-style: none;
    text-decoration: none;
    font-size: 18px;
}
</style>