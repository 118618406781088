<template>
    <div v-for="item in new_period" :key="item.id">
        <div v-if="item.apply_deadline < now_date">
            <div class="parent-table">
                <h3>L'inscription au nouveau membre est fermée,</h3>
                <h3>consulter cette page prochenaiment</h3>
                <br>
                <h3><a href="https://www.minevam-kigobe.org">Retour</a></h3>
            </div>
        </div>
        <div v-else>
            <div class="container-modal">
                <div class="content-modal">
                    <div class="title-modal">
                        <h5>Kwiyandikisha</h5>
                        <a href="https://www.minevam-kigobe.org">X</a>
                    </div>
                    <div class="content">
                        <h5>Minevam Kigobe Promotion {{ item.name }}</h5>
                        <form class="form" enctype="multipart/form-data">
                            <label for="lastName" class="placeholder">Izina</label>
                            <input id="lastName" class="input" v-model="form.lastName" type="text" required />
                            <label for="firstName" class="placeholder">Itazirano</label>
                            <input id="firstName" class="input" v-model="form.firstName" type="text" required />
                            <label for="phone1" class="placeholder">Nimero ya terefone 1</label>
                            <input id="phone1" class="input" v-model="form.phone1" type="text" />
                            <label for="phone2" class="placeholder">Nimero ya terefone 2</label>

                            <input id="phone2" class="input" v-model="form.phone2" type="text" />
                            <label for="address" class="placeholder">Aho uba</label>

                            <input id="address" class="input" v-model="form.address" type="text" />
                            <label for="" class="">Igitsina</label>
                            <div class="t-colomn">
                                <span>
                                    <input type="radio" id="Male" value="Male" v-model="form.gender">
                                    <label for="Male">Gabo</label>
                                </span>
                                <span>
                                    <input type="radio" id="Female" value="Female" v-model="form.gender">
                                    <label for="Female">Gore</label>
                                </span>
                            </div>
                            <label for="" class="">Etat civil</label>
                            <div class="t-colomn">
                                <span>
                                    <input type="radio" id="Single" value="Single" v-model="form.civilStatus">
                                    <label for="Single">Sindubaka</label>
                                </span>
                                <span>
                                    <input type="radio" id="Married" value="Married" v-model="form.civilStatus">
                                    <label for="Married">Ndubatse</label>
                                </span>
                                <span>
                                    <input type="radio" id="Divorced" value="Divorced" v-model="form.civilStatus">
                                    <label for="Divorced">Narahukanye</label>
                                </span>
                                <span>
                                    <input type="radio" id="Widow" value="Widow" v-model="form.civilStatus">
                                    <label for="Widow">Narapfakaye</label>
                                </span>
                            </div>
                            <div class="input-custom">
                                <input id="image1" type="file" accept=".jpeg,.jpg,.png" @change="e => loadImage(e)">
                                <label for="Photo" class="Photo">Photo</label>
                                <div class="imageLoad">
                                    <div class="image" @click="clickOn('image1')">
                                        <img :src="!!form.photo ? form.photo : '/static/logo.png'" alt="">
                                    </div>
                                </div>
                                <small>{{ logs }}</small>
                            </div>
                            <label for="firstName" class="placeholder">Wotubwira muri make ingene vyagenze igihe
                                wakira agakiza + aho wakiririye agakiza(ikibanza n'umwaka)</label>
                            <textarea id="bornAgainShortStoryAndWhenWhere" class="input"
                                v-model="form.bornAgainShortStoryAndWhenWhere" type="text" rows="5"
                                cols="70"></textarea>
                            <label for="" class="">Warabatijwe</label>
                            <div class="t-colomn">
                                <span>
                                    <input type="radio" id="baptised" :value="true" v-model="form.baptised">
                                    <label for="Ego">Ego</label>
                                </span>
                                <span>
                                    <input type="radio" id="baptised" :value="false" v-model="form.baptised">
                                    <label for="Oya">Oya</label>
                                </span>
                            </div>
                            <div class="t-colomn" v-if="form.baptised === true">
                                <label for="phone2" class="placeholder">Wabatirijwe hehe? ryari?</label>
                                <input id="baptemChurchDate" class="input" v-model="form.baptemChurchDate"
                                    type="text" />
                            </div>
                            <label for="recentChurchs" class="placeholder">Amashengero umaze gusengeramwo
                                n'igihe wamazeyo(Akarorero: Anglicane(2014-2020))</label>
                            <textarea id="recentChurchs" class="input" v-model="form.recentChurchs" type="text" rows="5"
                                cols="70"></textarea>
                            <label for="whyYouChangeChurch" class="placeholder">Tubwire muri make imvo itumye
                                uhindura ishengero</label>
                            <textarea id="whyYouChangeChurch" class="input" v-model="form.whyYouChangeChurch"
                                type="text" rows="5" cols="70"></textarea>
                            <div class="t-colomn">
                                <label for="" class="">Woba warasezeye abarongozi b'aho wahora?</label>
                                <span>
                                    <input type="radio" id="haveYouSayGoodbyeToRecentChurch" value="Ego"
                                        v-model="form.haveYouSayGoodbyeToRecentChurch">
                                    <label for="Ego">Ego</label>
                                </span>
                                <span>
                                    <input type="radio" id="haveYouSayGoodbyeToRecentChurch" value="Oya"
                                        v-model="form.haveYouSayGoodbyeToRecentChurch">
                                    <label for="Oya">Oya</label>
                                </span>
                            </div>
                            <div class="t-colomn" v-if="form.haveYouSayGoodbyeToRecentChurch === 'Oya'">
                                <label for="haveYouSayGoodbyeToRecentChurch" class="placeholder">Hari ico
                                    twobigufashamwo canke intambamyi ubifisemwo?</label>
                                <textarea id="haveYouSayGoodbyeToRecentChurch" class="input"
                                    v-model="form.whatDoYouWantChurchToDoForYou" type="text" rows="5"
                                    cols="70"></textarea>
                            </div>
                            <label for="" class="">Urashaka kuvugana n'umurongozi imvo yawe?</label>
                            <div class="t-colomn">
                                <span>
                                    <input type="radio" id="wantToTalkWithLeader" :value="true"
                                        v-model="form.wantToTalkWithLeader">
                                    <label for="Ego">Ego</label>
                                </span>
                                <span>
                                    <input type="radio" id="wantToTalkWithLeader" :value="false"
                                        v-model="form.wantToTalkWithLeader">
                                    <label for="Oya">Oya</label>
                                </span>
                            </div>
                        </form>
                        <h5>M.N: Kwiyandikisha muri Minevam Kigobe Promotion {{ item.name }} bizorangira itariki {{
        moment(item.apply_deadline).format("DD-MM-YYYY") }}, ninawo musi bashasha
                            bazotangurirako kwiga</h5>
                    </div>
                    <div class="buttons">
                        <button @click="onSubmit" type="submit">{{ loading ? "Rindira..." : btn }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    name: 'RegistrationView',
    components: {
    },
    data() {
        return {
            moment: moment,
            now_date: new Date().toISOString().substr(0, 10),
            new_period: [],
            logs: "",


            form: {
                firstName: "",
                lastName: "",
                phone1: "",
                phone2: "",
                address: "",
                gender: "Male",
                civilStatus: 'Single',
                photo: null,
                bornAgainShortStoryAndWhenWhere: "",
                baptised: false,
                baptemChurchDate: "",
                recentChurchs: "",
                whyYouChangeChurch: "",
                haveYouSayGoodbyeToRecentChurch: "",
                whatDoYouWantChurchToDoForYou: "",
                wantToTalkWithLeader: false,
                talk: false,
                talkWith: "",
                now_member: false,
                active: true,
                reason_for_departure: "",
                cell: '',
                period: null
            },


            btn: "Rungika",
            loading: false
        };
    },
    methods: {
        getNewPeriod() {
            this.axios.get('/new_period/')
                .then(response => {
                    this.new_period = response.data.results
                    this.form.period = this.new_period[0].id
                })
                .catch(error => {
                    console.log(error)
                })
        },
        clickOn(id) {
            let img = document.getElementById(id)
            img.click()
        },
        loadImage(event) {
            let file = event.target.files[0]
            if (file.size > 900_000) {
                this.logs = "Ne Pas depasser 800ko"
            } else {
                this.logs = ""
                let fr = new FileReader();
                let vue = this
                fr.onload = function () {
                    switch (event.target.id) {
                        case "image1":
                            vue.document1 = file
                            vue.form.photo = fr.result
                            break;
                        // case "image2":
                        // vue.document2 = file
                        // vue.img2_src = fr.result
                        // break; 
                        default:
                            vue.document1 = file
                            vue.form.photo = fr.result
                            break;
                    }
                }
                fr.readAsDataURL(file);
            }
        },
        onSubmit() {
            this.loading = true

            let formOgi = new FormData()
            formOgi.append("firstName", this.form.firstName)
            formOgi.append("lastName", this.form.lastName)
            formOgi.append("phone1", this.form.phone1)
            formOgi.append("phone2", this.form.phone2)
            formOgi.append("address", this.form.address)
            formOgi.append("civilStatus", this.form.civilStatus)
            formOgi.append("gender", this.form.gender)

            if (this.document1) {
                formOgi.append("photo", this.document1);
            }

            formOgi.append("bornAgainShortStoryAndWhenWhere", this.form.bornAgainShortStoryAndWhenWhere)
            formOgi.append("baptised", this.form.baptised)
            formOgi.append("baptemChurchDate", this.form.baptemChurchDate)
            formOgi.append("recentChurchs", this.form.recentChurchs)
            formOgi.append("whyYouChangeChurch", this.form.whyYouChangeChurch)
            formOgi.append("haveYouSayGoodbyeToRecentChurch", this.form.haveYouSayGoodbyeToRecentChurch)
            formOgi.append("whatDoYouWantChurchToDoForYou", this.form.whatDoYouWantChurchToDoForYou)
            formOgi.append("wantToTalkWithLeader", this.form.wantToTalkWithLeader)
            formOgi.append("talk", this.form.talk)
            formOgi.append("talkWith", this.form.talkWith)
            formOgi.append("now_member", this.form.now_member)
            formOgi.append("active", this.form.active)
            formOgi.append("reason_for_departure", this.form.reason_for_departure)
            formOgi.append("cell", this.form.cell)
            formOgi.append("period", this.form.period)


            this.axios.post('/new-member/', formOgi
            ).then(response => {
                console.log(response);
                setTimeout(() => {
                    this.loading = false;
                }, 2500);
                this.$toast.success(`Envoyée avec succès`)
                this.form = {}
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    this.$toast.error(error)
                })
        },
    },

    mounted() {
        this.getNewPeriod()
    },
};
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>
