<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Rapport de la zone</h5>
                <button class="backBash" @click="goDashboard">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <input class="dateCenter" type="date" v-model="selectedDate">
                    <div class="card-container">
                        <div class="card" v-for="family in this.$store.state.family" :key="family.id">
                            <div class="card-header">
                                {{ family.family }}
                            </div>
                            <label class="card-title">Accompagnement</label>
                            <div class="card-label">
                                <input type="radio" :id="'phone_Followup_' + family.id" value="phone_Followup"
                                    v-model="family.Followup">
                                <label :for="'phone_Followup_' + family.id">Par téléphone</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'physical_Followup_' + family.id" value="physical_Followup"
                                    v-model="family.Followup">
                                <label :for="'physical_Followup_' + family.id">Physique</label>
                            </div>
                            <div class="card-label">
                                <input type="radio" :id="'no_' + family.id" value="no" v-model="family.Followup">
                                <label :for="'no_' + family.id">No</label>
                            </div>
                            <div class="card-inputs">
                                <input type="text" placeholder="Responsable" v-model="family.responsible" required>
                                <input type="text" placeholder="thème" v-model="family.topic" required>
                                <input type="text" placeholder="Observation" v-model="family.observation" required>
                            </div>
                        </div>
                    </div>
                    <label class="placeholder">Commentaire:</label>
                    <input type="text" placeholder="Commentaire (defis, success...)" v-model="reportForm.comment"
                        required>
                    <small v-for="err in data_error?.comment" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Evénements après Mardi:</label>
                    <input type="text" placeholder="Evénements après Mardi" v-model="reportForm.after_cell_event"
                        required>
                    <label class="placeholder">Suggestions ou Urgence:</label>
                    <input type="text" placeholder="Suggestions ou Urgence" v-model="reportForm.suggestion_or_emergency"
                        required>
                </form>
            </div>
            <div class="buttons">
                <button @click="onSubmit" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>

export default {

    data() {
        return {
            selectedDate: new Date().toISOString().substr(0, 10),
            reportForm: {
                id: 1,
                comment: '',
                after_cell_event: '',
                suggestion_or_emergency: '',
            },
            errorMessage: '',
            btn: 'Envoyer',
            loading: false
        }
    },
    components: {
    },
    methods: {
        goDashboard() {
            this.$router.push('/dashboard/zone');
        },
        getZoneLeader() {
            this.axios.get('/logged-zone/', this.getHeaders()
            ).then(response => {
                this.$store.state.zone = response.data.results;
                this.$store.state.zoneOgi = this.$store.state.zone[0].name
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getZoneFamilyLeader() {
            this.axios.get('/zone-family/', this.getHeaders()
            ).then(response => {
                this.$store.state.family = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        onSubmit() {
            this.loading = true

            const zone_id = this.$store.state.zone[0].id

            const observaParticulaForm = {
                id: 1,
                comment: this.reportForm.comment,
                after_cell_event: this.reportForm.after_cell_event,
                suggestion_or_emergency: this.reportForm.suggestion_or_emergency,
                date: this.selectedDate,
                zone: zone_id,
            };

            this.axios.post('/zone-observation-particularity/', observaParticulaForm, this.getHeaders())
                .then(response => {
                    this.loading = false;
                    console.log(response);

                    this.$store.state.family.forEach(family => {
                        const zoneReportData = {
                            family: family.id,
                            Followup: family.Followup,
                            responsible: family.responsible,
                            topic: family.topic,
                            observation: family.observation,
                            date: this.selectedDate,
                            zone: this.$store.state.zone[0].id,
                        };

                        this.axios.post('/zone-report/', zoneReportData, this.getHeaders())
                            .then(response => {
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                                this.errorMessage = 'Please Selectionnez !!'
                                this.loading = false;
                            });
                    });
                    this.goDashboard()
                    this.loading = false;
                    this.$toast.success(`Envoyé avec succès`, {
                        position: "bottom",
                        maxToasts: 1
                    })
                })
                .catch(error => {
                    this.data_error = error.response?.data
                    console.log(error);
                    this.$toast.error(error, {
                        position: "bottom",
                        maxToasts: 1
                    })
                    this.loading = false;
                });
        },
        mounted() {
            this.getZoneLeader();
            this.getZoneFamilyLeader();
        }
    }
}
</script>

<style src="@/assets/css/modal/modal.css" scoped></style>
