<template>
    <div class="parent-table">
        <h3>Districts</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>District</th>
                        <th>Chef</th>
                        <th>Chef phone</th>
                        <th>Vice-chef</th>
                        <th>Vice-chef Phone</th>
                        <th>Noyau 1</th>
                        <th>Phone Noyau 1</th>
                        <th>Noyau 2</th>
                        <th>Phone Noyau 2</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.district" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>

                        <td>{{ item.chief }}</td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.chiefPhone1">{{ item.chiefPhone1 }}</a></span>
                            <span><a :href="'tel:' + item.chiefPhone2">{{ item.chiefPhone2 }}</a></span>
                        </td>

                        <td>{{ item.chiefVice }}</td>
                        <td class="t-colomn">
                            <span><a :href="'tel:' + item.chiefVicePhone1">{{ item.chiefVicePhone1 }}</a></span>
                            <span><a :href="'tel:' + item.chiefVicePhone2">{{ item.chiefVicePhone2 }}</a></span>
                        </td>

                        <td>{{ item.core1 }}</td>
                        <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore1">{{ item.Phonecore1
                                    }}</a></span></td>
                        <td>{{ item.core2 }}</td>
                        <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore2">{{ item.Phonecore2
                                    }}</a></span></td>

                        <td>
                            <button class="btn-action" @click="editDistrict(item)"><i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <districtModal :editDistrict="modify" @getDistrictLeader="getDistrictLeader" @close="close" v-if="modal">
        </districtModal>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import districtModal from '@/components/District/district_modal.vue'

export default {
    name: 'DistrictPageZ',
    components: {
        // moment,
        // OurSuccess
        districtModal,
    },
    data() {
        return {
            result: '',
            modal: false,
            modify: false,

            districtForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            },

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        editDistrict(item) {
            this.modal = true
            this.modify = true
            this.$store.state.districtOgi = item
        },
        close() {
            this.modal = false
        },
        getDistrictLeader(url = null) {
            if (!url) {
                url = '/district/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.district = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        exportExcel() {
            console.log(this.$store.state.district)
            const workSheet = XLSX.utils.json_to_sheet(this.$store.state.district)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'district.xlsx')
        },
    },
    mounted() {
        this.getDistrictLeader();
    }
};
</script>

<style></style>