<template>
    <div class="content-ogi">


        <div class="parent-table">
            <h3>Les Zones</h3>
            <div class="header-table">
                <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
                <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
                <input type="text" placeholder="cherche la page" v-model="searchQuery">
                <button @click=searchPage(searchQuery)>Appliquer</button>
                <p>{{ errorMessage }}</p>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Zone</th>
                            <th>District</th>
                            <th>Chef</th>
                            <th>Chef phone</th>
                            <th>Vice-chef</th>
                            <th>Vice-chef Phone</th>
                            <th>Noyau 1</th>
                            <th>Phone Noyau 1</th>
                            <th>Noyau 2</th>
                            <th>Phone Noyau 2</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.$store.state.zone" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.district_name }}</td>

                            <td>{{ item.chief }}</td>
                            <td class="t-colomn">
                                <span><a :href="'tel:' + item.chiefPhone1">{{ item.chiefPhone1 }}</a></span>
                                <span><a :href="'tel:' + item.chiefPhone2">{{ item.chiefPhone2 }}</a></span>
                            </td>

                            <td>{{ item.chiefVice }}</td>
                            <td class="t-colomn">
                                <span><a :href="'tel:' + item.chiefVicePhone1">{{ item.chiefVicePhone1 }}</a></span>
                                <span><a :href="'tel:' + item.chiefVicePhone2">{{ item.chiefVicePhone2 }}</a></span>
                            </td>

                            <td>{{ item.core1 }}</td>
                            <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore1">{{ item.Phonecore1
                                        }}</a></span></td>
                            <td>{{ item.core2 }}</td>
                            <td class="t-colomn"><span><a :href="'tel:' + item.Phonecore2">{{ item.Phonecore2
                                        }}</a></span></td>
                            <td>
                                <button class="btn-action" @click="editZone(item)"> <i class="fas fa-edit"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <zone_modal v-if="modal" @getZoneLeader="getZoneLeader" @close="close" :editZone="modify"
                @editZone="editZone">
            </zone_modal>
        </div>

    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import zone_modal from '@/components/Zone/zone_modal.vue'

export default {

    name: 'ZonePageZ',
    components: {
        // moment,
        // OurSuccess
        zone_modal
    },
    data() {
        return {
            result: '',
            modal: false,
            modify: false,
            success: false,

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editZone(item) {
            this.modal = true
            this.modify = true
            this.$store.state.zoneOgi = item
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getZoneLeader(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getZoneLeader(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getZoneLeader(url = null) {
            if (!url) {
                url = '/zone/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.zone = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getZoneLeader(`/zone/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        exportExcel() {
            console.log(this.$store.state.zone)
            const workSheet = XLSX.utils.json_to_sheet(this.$store.state.zone)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'zone.xlsx')
        },
    },
    mounted() {
        this.getZoneLeader();
    }
};
</script>

<style></style>