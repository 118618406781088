<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Membre du département</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Département:</label>
                    <select class="input" v-model="form.department" name="" id="">
                        <option selected value="" disabled>--------</option>
                        <option v-for="item in departments" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                    <small v-for="err in data_error?.department" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Taper et selectioner le member:</label>
                    <div class="search-member">
                        <input placeholder="Taper et selectioner le member" type="text" v-model="searchMember">
                        <h5 class="btn-action" @click="search">
                            <i class="fas fa-search"></i>
                        </h5>
                    </div>
                    <small v-for="err in data_error?.member" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn" v-for="item in filteredMember.slice(0, 8)" :key="item.id">
                        <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                            v-model="form.member" />
                        <label v-if="!!searchMember" :for="item.id" @click="updateMember(item.id)">
                            {{ item.lastName }} {{ item.firstName }}, {{ item.cell.cell }}
                        </label>
                    </div>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['editDeptMember'],
    data() {
        return {
            searchMember: '',
            //for search 
            members: [],
            result: "",
            results: [],
            keyword: "",
            departmentMembers_id: null,
            username: '',

            form: {
                department: '',
                member: ''
            },
            departments: [],
            data_error: {},

            btn: 'Enregistrer',
            loading: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getDepartmentMember() {
            this.$emit('getDepartmentMember')
        },
        updateMember(id) {
            this.form.member = id;
            this.searchMember = this.filteredMember.find(item => item.id === id)?.lastName || "";
        },
        search() {
            axios.get(`/members/?search=${this.searchMember}`, this.headers)
                .then((response) => {
                    this.members = response.data.results
                    console.log(this.members)
                }).catch((error) => {
                    console.log(error)
                    console.log('wapi')
                })
        },
        save() {
            if (this.editDeptMember) {
                this.loading = true
                let data = new FormData()
                data.append('department', this.form.department)
                data.append('member', this.form.member)

                axios.put('/department-member/' + this.departmentMembers_id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getDepartmentMember()
                        this.$toast.success(`Membre du département Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('department', this.form.department)
                data.append('member', this.form.member)

                axios.post('/department-member/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getDepartmentMember()
                        this.$toast.success(`Membre du département Ajouté`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        getDepartment() {
            axios.get('/department/', this.headers)
                .then((res) => {
                    this.departments = res.data.results
                })
                .catch(error => {
                    this.$toast.error(error)
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getDepartment()
        this.search()
        this.getDepartmentMember()

        if (this.editDeptMember) {
            this.departmentMembers_id = this.$store.state.departmentMembers.id
            this.form.department = this.$store.state.departmentMembers.department.id
            this.form.member = this.$store.state.departmentMembers.member.id
            this.searchMember = this.$store.state.departmentMembers.member.lastName
            this.btn = 'Modifier'
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        },
        filteredMember() {
            return this.members.filter(member =>
                member.lastName.toLowerCase().includes(this.searchMember.toLowerCase()) ||
                member.firstName.toLowerCase().includes(this.searchMember.toLowerCase())
            );
        }
    }
}
</script>


<style src="../../assets/css/modal/modal.css" scoped></style>