<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Recette</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Motif</label>
                    <select v-model="form.motif">
                        <option selected value="" disabled>------</option>
                        <option value="Action de grace">Action de grâce</option>
                        <option value="Offrande">Offrande</option>
                        <option value="Soutien specifique">Soutien spécifique</option>
                        <option value="Autre">Autre</option>
                    </select>
                    <small v-for="err in data_error?.motif" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Montant</label>
                    <input v-model="form.amount" type="number" required placeholder=" " />
                    <small v-for="err in data_error?.amount" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="">Monnaie</label>
                    <div class="t-colomn">
                        <span>
                            <input type="radio" id="BIF" value="BIF" v-model="form.currency">
                            <label for="BIF">BIF</label>
                        </span>
                        <span>
                            <input type="radio" id="USD" value="USD" v-model="form.currency">
                            <label for="USD">USD</label>
                        </span>
                        <span>
                            <input type="radio" id="EURO" value="EURO" v-model="form.currency">
                            <label for="EURO">EURO</label>
                        </span>
                        <br>
                    </div>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['editIncome'],
    data() {
        return {
            form: {
                motif: '',
                amount: '',
                currency: 'BIF'
            },
            btn: 'Enregistrer',
            loading: false,
        }
    },
    methods: {
        save() {
            if (this.editIncome) {
                this.loading = true
                let data = new FormData()
                data.append('motif', this.form.motif)
                data.append('amount', this.form.amount)
                data.append('currency', this.form.currency)
                axios.put('/income/' + this.$store.state.income.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getIncome()
                        this.$toast.success(`Recette Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('motif', this.form.motif)
                data.append('amount', this.form.amount)
                data.append('currency', this.form.currency)
                axios.post('/income/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getIncome()
                        this.$toast.success(`Recette Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        close() {
            this.$emit('close')
        },
        getIncome() {
            this.$emit('getIncome')
        }
    },
    mounted() {
        this.getIncome()
        if (this.editIncome) {
            this.form.motif = this.$store.state.income.motif
            this.form.amount = this.$store.state.income.amount
            this.form.currency = this.$store.state.income.currency
            this.btn = 'Modifier'
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>