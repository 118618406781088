<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>District</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Taper et selectioner l'utilisateur:</label>
                    <input placeholder="Taper et selectioner l'utilisateur" type="text" v-model="searchUser">
                    <small v-for="err in data_error?.user" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn">
                        <div v-for="item in filteredUser.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.user" />
                            <label v-if="!!searchUser" :for="item.id" @click="updateUser(item.id)">
                                {{ item.username }}, {{ item.role }}
                            </label>
                        </div>
                    </div>
                    <label>District</label>
                    <input v-model="form.name" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.name" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Chef</label>
                    <input v-model="form.chief" type="text" required placeholder=" " />
                    <label>Numero Chef 1</label>
                    <input v-model="form.chiefPhone1" type="text" required placeholder=" " />
                    <label>Numero Chef 2</label>
                    <input v-model="form.chiefPhone2" type="text" required placeholder=" " />
                    <label>Vice Chef</label>
                    <input v-model="form.chiefVice" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 1</label>
                    <input v-model="form.chiefVicePhone1" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 2</label>
                    <input v-model="form.chiefVicePhone2" type="text" required placeholder=" " />
                    <label>Noyau 1</label>
                    <input v-model="form.core1" type="text" required placeholder=" " />
                    <label>Noyau Phone 1</label>
                    <input v-model="form.Phonecore1" type="text" required placeholder=" " />
                    <label>Noyau 2</label>
                    <input v-model="form.core2" type="text" required placeholder=" " />
                    <label>Noyau Phone 2</label>
                    <input v-model="form.Phonecore2" type="text" required placeholder=" " />
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['editDistrict'],
    data() {
        return {
            users: [],
            searchUser: '',
            //for search user
            result: "",
            results: [],
            founds: [],
            keyword: "",
            user: null,
            username: '',

            form: {
                name: '',
                chief: '',
                chiefPhone1: '',
                chiefPhone2: '',
                chiefVice: '',
                chiefVicePhone1: '',
                chiefVicePhone2: '',
                core1: '',
                Phonecore1: '',
                core2: '',
                Phonecore2: '',
                user: ''
            },
            data_error: {},

            btn: 'Enregistrer',
            loading: false
        }
    },

    methods: {
        getDistrictLeader() {
            this.$emit('getDistrictLeader')
        },
        close() {
            this.$emit('close')
        },
        updateUser(id) {
            this.form.user = id;
            this.searchUser = this.filteredUser.find(item => item.id === id)?.username || "";
        },
        save() {
            if (this.editDistrict) {
                this.loading = true
                let data = new FormData()
                data.append('name', this.form.name)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('user', this.form.user)
                axios.put('/district/' + this.$store.state.districtOgi.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.loading = false
                        this.getDistrictLeader();

                        this.$toast.success(`District Modifiée`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('name', this.form.name)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('user', this.form.user)
                axios.post('/district/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.loading = false
                        this.getDistrictLeader();

                        this.$toast.success(`District Ajoutée`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
    },
    mounted() {
        this.getDistrictLeader()

        if (this.editDistrict) {
            this.btn = 'Modifier'
            this.form.name = this.$store.state.districtOgi.name
            this.form.chief = this.$store.state.districtOgi.chief
            this.form.chiefPhone1 = this.$store.state.districtOgi.chiefPhone1
            this.form.chiefPhone2 = this.$store.state.districtOgi.chiefPhone2
            this.form.chiefVice = this.$store.state.districtOgi.chiefVice
            this.form.chiefVicePhone1 = this.$store.state.districtOgi.chiefVicePhone1
            this.form.chiefVicePhone2 = this.$store.state.districtOgi.chiefVicePhone2
            this.form.core1 = this.$store.state.districtOgi.core1
            this.form.Phonecore1 = this.$store.state.districtOgi.Phonecore1
            this.form.core2 = this.$store.state.districtOgi.core2
            this.form.Phonecore2 = this.$store.state.districtOgi.Phonecore2
            this.form.user = this.$store.state.districtOgi.user

            this.searchUser = this.$store.state.districtOgi.username
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        },
        filteredUser() {
            return this.$store.state.users.filter(user =>
                user.username.toLowerCase().includes(this.searchUser.toLowerCase()) ||
                user.role.toLowerCase().includes(this.searchUser.toLowerCase())
            );
        }
    }
}
</script>

<style src="../../assets/css/modal/modal.css" scoped></style>