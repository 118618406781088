<template>
    <div class="content-ogi">

        <div class="parent-table">
            <div class="filter_pr">
                <h3>Les Présences des membres de la {{ this.$store.state.zoneOgi }}</h3>
            </div>
            <div class="filter_pr">
                <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
                <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
                <button @click="getZoneMemberAttendance()">Trier</button>
            </div>
            <div class="filter_pr">
                <div class="card-pourcentage-1">
                    <div class="card-body">
                        <p class="card-pr">Présence</p>
                        <div class="card_total">
                            <div>Total: {{ this.$store.state.memberAttendance.presence_total }}</div>
                            <div>|</div>
                            <div>{{ this.$store.state.memberAttendance.presence_percentage }}%</div>
                        </div>
                    </div>
                </div>
                <div class="card-pourcentage-3">
                    <div class="card-body">
                        <p class="card-pr">Justifié</p>
                        <div class="card_total">
                            <div>Total: {{ this.$store.state.memberAttendance.justify_total }}</div>
                            <div>|</div>
                            <div>{{ this.$store.state.memberAttendance.justify_percentage }}%</div>
                        </div>
                    </div>
                </div>
                <div class="card-pourcentage-2">
                    <div class="card-body">
                        <p class="card-pr">Absence</p>
                        <div class="card_total">
                            <div>Total: {{ this.$store.state.memberAttendance.absence_total }}</div>
                            <div>|</div>
                            <div>{{ this.$store.state.memberAttendance.absence_percentage }}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Nom et Prénom </th>
                            <th>Présence</th>
                            <th>Observation</th>
                            <th>Accompagnement</th>
                            <th>Cellule</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.$store.state.memberAttendance.results" :key="item.id">
                            <td>{{ item.member_name }}</td>
                            <td v-if="item.attendance === 'present'">Présent</td>
                            <td v-else-if="item.attendance === 'absent'">Absent</td>
                            <td v-else>Justifié</td>
                            <td>{{ item.observation }}</td>
                            <td v-if="item.Followup === 'phone_Followup'">Par téléphone</td>
                            <td v-else-if="item.Followup === 'physical_Followup'">Physique</td>
                            <td v-else>no</td>
                            <td>{{ item.cell_name }}</td>
                            <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                    <button v-for="pageNumber in displayedPages" :key="pageNumber"
                        @click="getZoneMemberAttendance(pageNumber)" :class="{ active: currentPage === pageNumber }">{{
                    pageNumber }}</button>
                    <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                    <button v-if="!displayedPages.includes(totalPages)" @click="getZoneMemberAttendance(totalPages)">
                        {{ totalPages }}
                    </button>
                    <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
                </div>
            </div>
        </div>

        <br>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

            date_from: '',
            date_to: ''
        }
    },
    components: {
        // moment,
    },
    methods: {
        getZoneLeader() {
            this.axios.get('/logged-zone/', this.getHeaders()
            ).then(response => {
                this.$store.state.zone = response.data.results;
                this.$store.state.zoneOgi = this.$store.state.zone[0].name
            })
                .catch(error => {
                    console.log(error)
                })
        },
        getZoneFamilyLeader() {
            this.axios.get('/zone-family/', this.getHeaders()
            ).then(response => {
                this.$store.state.family = response.data.results;
            })
                .catch(error => {
                    console.log(error)
                })
        },
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getZoneMemberAttendance(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getZoneMemberAttendance(this.currentPage); // Pass current page number
            }
        },
        getZoneMemberAttendance(pageNumber = null) {
            let url = `/zone-member-attendance/?date_range=${this.date_from},${this.date_to}`;
            if (pageNumber !== null) {
                url += `&page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }

            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.memberAttendance = response.data;
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
    },
    mounted() {
        this.getZoneLeader();
        this.getZoneFamilyLeader();
        this.getZoneMemberAttendance();
    },
}
</script>

<style></style>