<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Utilisateur</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Username</label>
                    <input v-model="form.username" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.username" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="role">Role</label>

                    <select id="input" v-model="form.role">
                        <option selected value="" disabled>------</option>
                        <option value="Cell">Cell</option>
                        <option value="Family">Family</option>
                        <option value="Zone">Zone</option>
                        <option value="District">District</option>
                        <option value="Staff">Staff</option>
                    </select>
                    <small v-for="err in data_error?.role" :key="err.id">
                        {{ err }}
                    </small>
                    <label for="password">password</label>
                    <input id="password" v-model="form.password" type="password" required placeholder=" " />
                    <small v-for="err in data_error?.password" :key="err.id">
                        {{ err }}
                    </small>
                    <small>{{ data_error.detail }}</small>
                    <small>{{ data_error.new_password }}</small>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['editUser'],
    data() {
        return {
            form: {
                id: '',
                username: '',
                password: '',
                role: ''
            },
            data_error: {},
            loading: false,
            btn: 'Enregistrer'
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getUsers() {
            this.$emit('getUsers')
        },
        save() {
            if (this.editUser) {
                this.loading = true;
                let data = {
                    new_username: this.form.username, //new_username is a name from serializer
                    new_password: this.form.password,
                    new_role: this.form.role
                };

                axios.post(`/users/${this.form.id}/change_password/`, data, this.getHeaders())
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getUsers();
                        this.$toast.success('Utilisateur Modifiée');
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            } else {
                this.loading = true;
                let data = {
                    username: this.form.username,
                    password: this.form.password,
                    role: this.form.role
                };

                axios.post('/user-signup/', data, this.getHeaders())
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getUsers();
                        this.$toast.success('Utilisateur Ajoutée');
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            }
        },
    },
    mounted() {
        this.getUsers()
        if (this.editUser) {
            this.form.username = this.$store.state.users.username;
            this.form.role = this.$store.state.users.role;
            this.form.id = this.$store.state.users.id;
            this.btn = 'Modifier'
        }
    },
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>