<template>
    <div class="content-ogi">

        <div class="parent-table">
            <div class="filter_pr">
                <h3>Les particularites dans les observations des familles</h3>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Familles</th>
                            <th>Commentaire</th>
                            <th>Evénement après Mardi</th>
                            <th>Suggestion & urgence</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.$store.state.familyObservationParticularity" :key="item.id">
                            <td>{{ item.family_name }}</td>
                            <td>{{ item.comment }}</td>
                            <td>{{ item.after_cell_event }}</td>
                            <td>{{ item.suggestion_or_emergency }}</td>
                            <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                    <button v-for="pageNumber in displayedPages" :key="pageNumber"
                        @click="getZoneFamilyObservationParticularity(pageNumber)"
                        :class="{ active: currentPage === pageNumber }">{{ pageNumber }}</button>
                    <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                    <button v-if="!displayedPages.includes(totalPages)"
                        @click="getZoneFamilyObservationParticularity(totalPages)">
                        {{ totalPages }}
                    </button>
                    <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
                </div>
            </div>
        </div>


        <br>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,
            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1
        }
    },
    components: {
        // moment,
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getZoneFamilyObservationParticularity(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getZoneFamilyObservationParticularity(this.currentPage); // Pass current page number
            }
        },
        getZoneFamilyObservationParticularity(pageNumber = null) {
            let url = `/logged-zone-familyObservationParticularity/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.familyObservationParticularity = response.data.results;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        }
    },
    mounted() {
        this.getZoneFamilyObservationParticularity();
    },
}
</script>

<style>
.submit {
    background-color: #4285f4;
    color: white;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.submit:hover {
    background-color: #fff;
    color: #4285f4;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.rapport {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
</style>