<template>
    <div class="parent-table">
        <div class="filter_pr">
            <h3>Les particularites dans les observations des zones</h3>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Commentaire</th>
                        <th>Evénement après Mardi</th>
                        <th>Suggestion & urgence</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.zoneObservationParticularity" :key="item.id">
                        <td>{{ item.zone_name }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.after_cell_event }}</td>
                        <td>{{ item.suggestion_or_emergency }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <button @click="decrementPageZone" :disabled="currentPageZone === 1">&lt;</button>
                <button v-for="pageNumberZone in displayedPagesZone" :key="pageNumberZone"
                    @click="getDistrictZoneObservationParticularity(pageNumberZone)"
                    :class="{ active: currentPageZone === pageNumberZone }">{{ pageNumberZone }}</button>
                <span v-if="totalPagesZone > displayedPagesZone[displayedPagesZone.length - 1]">...</span>
                <button v-if="!displayedPagesZone.includes(totalPagesZone)"
                    @click="getDistrictZoneObservationParticularity(totalPagesZone)">
                    {{ totalPagesZone }}
                </button>
                <button @click="incrementPageZone" :disabled="currentPageZone === totalPagesZone">&gt;</button>
            </div>
        </div>
    </div>

    <div class="parent-table">
        <div class="filter_pr">
            <h3>Les particularites dans les observations des familles</h3>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Famille</th>
                        <th>Commentaire</th>
                        <th>Evénement après Mardi</th>
                        <th>Suggestion & urgence</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.familyObservationParticularity" :key="item.id">
                        <td>{{ item.family_name }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.after_cell_event }}</td>
                        <td>{{ item.suggestion_or_emergency }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                <button v-for="pageNumber in displayedPages" :key="pageNumber"
                    @click="getDistrictFamilyObservationParticularity(pageNumber)" :class="{ active: currentPage === pageNumber }">{{
                        pageNumber }}</button>
                <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                <button v-if="!displayedPages.includes(totalPages)" @click="getDistrictFamilyObservationParticularity(totalPages)">
                    {{ totalPages }}
                </button>
                <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
            </div>
        </div>
    </div>

    <br>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,
            success: false,

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,

            errorMessageZone: '',
            previousUrlZone: null,
            nextUrlZone: null,
            currentPageZone: 1,
            totalPagesZone: 1,
            pageSizeZone: 40,
        }
    },
    components: {
        // moment,
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getDistrictFamilyObservationParticularity(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getDistrictFamilyObservationParticularity(this.currentPage); // Pass current page number
            }
        },
        getDistrictFamilyObservationParticularity(pageNumber = null) {
            let url = `/logged-district-familyObservationParticularity/`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.familyObservationParticularity = response.data.results;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        /// For Zone ///
        incrementPageZone() {
            if (this.nextUrlZone) {
                this.currentPageZone++;
                this.getDistrictZoneObservationParticularity(this.currentPageZone); // Pass current page number
            }
        },
        decrementPageZone() {
            if (this.previousUrlZone) {
                this.currentPageZone--;
                this.getDistrictZoneObservationParticularity(this.currentPageZone); // Pass current page number
            }
        },
        getDistrictZoneObservationParticularity(pageNumberZone = null) {
            let url = `/logged-district-zoneObservationParticularity/`;
            if (pageNumberZone !== null) {
                url += `?page=${pageNumberZone}`;
                this.currentPageZone = pageNumberZone;
            } else {
                url;
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.zoneObservationParticularity = response.data.results;
                    this.previousUrlZone = response.data.previous;
                    this.nextUrlZone = response.data.next;
                    this.totalPagesZone = Math.ceil(response.data.count / this.pageSizeZone);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
        displayedPagesZone() {
            const rangeStart = Math.max(1, this.currentPageZone - 1);
            const rangeEnd = Math.min(this.totalPagesZone, this.currentPageZone + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        }
    },
    mounted() {
        this.getDistrictFamilyObservationParticularity();
        this.getDistrictZoneObservationParticularity();
    },
}
</script>

<style>
.submit {
    background-color: #4285f4;
    color: white;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.submit:hover {
    background-color: #fff;
    color: #4285f4;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.rapport {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
</style>