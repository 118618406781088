<template>
    <div class="parent-table">
        <h3>Les promotions</h3>
        <div class="header-table">
            <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText">
                <button class="btn-action">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Promotion</th>
                        <th>Date du fin d'inscription</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ moment(item.apply_deadline).format("DD-MM-YYYY, hh-mm") }}</td>
                        <td>
                            <button class="btn-action" @click="editPeriod(item)"><i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <addPeriod @getPeriod="getPeriod" :editPeriod="modify" @close="close" v-if="modal"></addPeriod>
    </div>
</template>


<script>
import moment from 'moment'
import * as XLSX from 'xlsx'
import addPeriod from '@/components/Period/period_modal.vue'


export default {
    name: 'periodPageZ',
    components: {
        // moment,
        // OurSuccess
        addPeriod
    },
    data() {
        return {
            moment: moment,

            result: '',
            success: false,
            modal: false,
            modify: false,
            periodForm: {
                sender: '',
                notification: '',
                date: new Date().toISOString().substr(0, 10),
            },
            searchText: "",
        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editPeriod(item) {
            this.modal = true
            this.modify = true
            this.$store.state.period = item
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'Promotion.xlsx')
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.periods, this.searchText);
        },
    },
    mounted() {
    }
};
</script>

<style></style>