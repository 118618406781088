<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Modifier nom d'utilisateur</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Nouveau nom d'utilisateur</label>
                    <input v-model="form.username" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.username" :key="err.id">
                        {{ err }}
                    </small>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            form: {
                username: ''
            },
            data_error: {},
            loading: false,
            btn: 'Modifier'
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            if (confirm("Voulez-vous vraiment modifier le nom d'utilisateur?")) {
                this.loading = true;
                let data = {
                    username: this.form.username,
                };

                axios.put(`/user-edit/`, data, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.$toast.success("Nom d'utilisateur a été Modifiée");
                    })
                    .catch(error => {
                        console.log(error);
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    });
            }
        },
    },
    mounted() {
    this.$store.state.user = JSON.parse(localStorage.getItem('user'))
    },
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>