<template>
  <div class="sidebar">
    <div class="header-sidebar">
      <h4>Minevam Kigobe</h4>
      <p v-if="$store.state.user.role === 'Member'">Member</p>
      <p v-if="$store.state.user.role === 'Cell'">Cellule</p>
      <p v-else-if="$store.state.user.role === 'Family'">Famille</p>
      <p v-else-if="$store.state.user.role === 'Zone'">Zone</p>
      <p v-else-if="$store.state.user.role === 'District'">District</p>
      <p v-else-if="$store.state.user.role === 'Staff'">Administration</p>
      <p v-else></p>

    </div>
    <div class="sidebar-content" v-if="filteredLinks">
      <router-link v-for="(link, index) in filteredLinks" :key="index" class="dash-link" @click="closeSideBar"
        :to="link.to">
        <i :class="link.icon"></i>{{ link.title }}
      </router-link>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>


<script>
export default {
  name: "OurSidebar",
  data() {
    return {
      role: null,
      linksHome: [
        { to: "/" }
      ],
      linksMember: [
        { to: "/dashboard/member", icon: "fas fa-tachometer-alt", title: "Tableau de bord" },
        { to: "/dashboard/member-account", icon: "fas fa-user", title: "Compte" },
      ],
      linksCell: [
        { to: "/dashboard/cell", icon: "fas fa-tachometer-alt", title: "Tableau de bord" },
        { to: "/dashboard/cell-ajouterRapport", icon: "fas fa-add", title: "Ajouter Rapport" },
        { to: "/dashboard/cell-member", icon: "fas fa-list", title: "Les Membres" },
        { to: "/dashboard/cell-presence", icon: "fas fa-file-alt", title: "Les Présences" },
        { to: "/dashboard/cell-rapport", icon: "fas fa-file-alt", title: "Les Rapports" },
        { to: "/dashboard/cell-communication", icon: "fas fa-comments", title: "Communication" },
        { to: "/dashboard/cell-account", icon: "fas fa-user", title: "Compte" },
      ],
      linksFamily: [
        { to: "/dashboard/family", icon: "fas fa-tachometer-alt", title: "Tableau de bord" },
        { to: "/dashboard/family-ajouterRapport", icon: "fas fa-add", title: "Ajouter Rapport" },
        { to: "/dashboard/family-presence", icon: "fas fa-file-alt", title: "Les Présences" },
        { to: "/dashboard/family-rapport", icon: "fas fa-file-alt", title: "Les Rapports" },
        { to: "/dashboard/family-communication", icon: "fas fa-comments", title: "Communication" },
        { to: "/dashboard/family-account", icon: "fas fa-user", title: "Compte" },
      ],
      linksZone: [
        { to: "/dashboard/zone", icon: "fas fa-tachometer-alt", title: "Tableau de bord" },
        { to: "/dashboard/zone-ajouterRapport", icon: "fas fa-add", title: "Ajouter Rapport" },
        { to: "/dashboard/zone-presence", icon: "fas fa-file-alt", title: "Les Présences" },
        { to: "/dashboard/zone-rapport", icon: "fas fa-file-alt", title: "Les Rapports" },
        { to: "/dashboard/zone-observation", title: 'Les observations', icon: 'fas fa-file-alt' },
        { to: "/dashboard/zone-communication", icon: "fas fa-comments", title: "Communication" },
        { to: "/dashboard/zone-account", icon: "fas fa-user", title: "Compte" },
      ],
      linksDistrict: [
        { to: "/dashboard/district", title: 'Tableau de bord', icon: 'fas fa-tachometer-alt' },
        { to: "/dashboard/district-ajouterRapport", title: 'Ajouter Rapport', icon: 'fas fa-add' },
        { to: "/dashboard/district-presence", title: 'Les Présences', icon: 'fas fa-file-alt' },
        { to: "/dashboard/district-rapport", title: 'Les Rapports', icon: 'fas fa-file-alt' },
        { to: "/dashboard/district-observation", title: 'Les observations', icon: 'fas fa-file-alt' },
        { to: "/dashboard/district-communication", title: 'Communication', icon: 'fas fa-comments' },
        { to: "/dashboard/district-account", title: 'Compte', icon: 'fas fa-user' },
      ],
      linksStaff: [
        { to: "/dashboard/staff", title: 'Tableau de bord', icon: 'fas fa-tachometer-alt' },
        { to: "/dashboard/staff-user", title: 'Utilisateurs', icon: 'fas fa-list' },
        { to: "/dashboard/staff-communication", title: 'Communications', icon: 'fas fa-comments' },
        { to: "/dashboard/staff-period", title: 'Promotions', icon: 'fas fa-calendar-alt' },
        { to: "/dashboard/staff-member", title: 'Membres', icon: 'fas fa-list' },
        { to: "/dashboard/staff-district", title: 'Districts', icon: 'fas fa-users' },
        { to: "/dashboard/staff-rapportDistrict", title: 'Rapport des districts', icon: 'fas fa-file-alt' },
        { to: "/dashboard/staff-zone", title: 'Zones', icon: 'fas fa-users' },
        { to: "/dashboard/staff-rapportZone", title: 'Rapport des zones', icon: 'fas fa-file-alt' },
        { to: "/dashboard/staff-family", title: 'Familles', icon: 'fas fa-users' },
        { to: "/dashboard/staff-rapportFamily", title: 'Rapport des familles', icon: 'fas fa-file-alt' },
        { to: "/dashboard/staff-cellHost", title: 'Hôtes des cellule', icon: 'fas fa-list' },
        { to: "/dashboard/staff-cell", title: 'Cellules', icon: 'fas fa-users' },
        { to: "/dashboard/staff-rapportCell", title: 'Rapport des cellules', icon: 'fas fa-file-alt' },
        { to: "/dashboard/staff-income", title: 'Recettes', icon: 'fas fa-money-bill-alt' },
        { to: "/dashboard/staff-outcomeType", title: 'Type de Dépense', icon: 'fas fa-list' },
        { to: "/dashboard/staff-beneficiary", title: 'Bénéficières', icon: 'fas fa-list' },
        { to: "/dashboard/staff-outcome", title: 'Dépenses', icon: 'fas fa-money-bill-alt' },
        { to: "/dashboard/staff-department", title: 'Départements', icon: 'fas fa-list' },
        { to: "/dashboard/staff-departmentMember", title: 'Membres du Depart', icon: 'fas fa-list' },
        { to: "/dashboard/staff-category", title: 'Categories', icon: 'fas fa-list' },
        { to: "/dashboard/staff-objective", title: 'Objectives', icon: 'fas fa-list' },
        { to: "/dashboard/staff-account", title: 'Compte', icon: 'fas fa-user' },

      ],
    };
  },
  watch: {
    filteredLinks(newValue) {
      this.filteredLinks = newValue.map((link) => ({
        ...link,
        to: link.to || '/',
      }));
    },
  },
  methods: {
    closeSideBar() {
      if (window.innerWidth <= 910) {
        this.toggleSidebar();
      }
    },
    toggleSidebar() {
      this.$store.commit('toggleSidebar');
    },
  },
  computed: {
    filteredLinks() {
      const user = JSON.parse(localStorage.getItem('user'))
      switch (user.role) {
        case "Member":
          return this.linksMember;
        case "Cell":
          return this.linksCell;
        case "Family":
          return this.linksFamily;
        case "Zone":
          return this.linksZone;
        case "District":
          return this.linksDistrict;
        case "Staff":
          return this.linksStaff;
        default:
          return this.linksHome
      }
    },
  },
  mounted() {
    this.$store.state.user = JSON.parse(localStorage.getItem('user'))
  }
};
</script>

<style scoped>
.header-sidebar {
  padding: 10px;
}

.sidebar {
  width: 250px;
  background-color: var(--sidebar_bg);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;

}

.sidebar-content {
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0px 0;
}

.header-sidebar h4 {
  margin: 0;
  color: var(--white);
}

.header-sidebar p {
  margin: 0;
  color: var(--gray_p);
}

.dash-link {
  padding: 15px;
  text-decoration: none;
  color: var(--gray_eee);
  display: flex;
  gap: 10px;
  border-radius: 0 50px 50px 0;
}

.dash-link:hover {
  background: linear-gradient(to right, #f5983a, #4285f4);
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 10px;
}

.router-link-exact-active {
  border-left: 4px solid #4285f4;
}
</style>
