<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Famille</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Zone</label>
                    <select v-model="form.zone"   name="" id="">
                        <option  selected value="" disabled >--------</option>
                        <option v-for="zone in zones" :key="zone.id" :value="zone.id" >{{zone.name}}</option>
                    </select>
                    <small v-for="err in data_error?.zone" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Taper et selectioner l'utilisateur:</label>
                    <input placeholder="Taper et selectioner l'utilisateur" type="text" v-model="searchUser">
                    <small v-for="err in data_error?.user" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn">
                        <div v-for="item in filteredUser.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.user" />
                            <label v-if="!!searchUser" :for="item.id" @click="updateUser(item.id)">
                                {{ item.username }}, {{ item.role }}
                            </label>
                        </div>
                    </div>
                    <label>Famille</label>
                    <input v-model="form.family" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.family" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Chef</label>
                    <input v-model="form.chief" type="text" required placeholder=" " />
                    <label>Numero Chef 1</label>
                    <input v-model="form.chiefPhone1" type="text" required placeholder=" " />
                    <label>Numero Chef 2</label>
                    <input v-model="form.chiefPhone2" type="text" required placeholder=" " />
                    <label>Vice Chef</label>
                    <input v-model="form.chiefVice" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 1</label>
                    <input v-model="form.chiefVicePhone1" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 2</label>
                    <input v-model="form.chiefVicePhone2" type="text" required placeholder=" " />
                    <label>Noyau 1</label>
                    <input v-model="form.core1" type="text" required placeholder=" " />
                    <label>Noyau Phone 1</label>
                    <input v-model="form.Phonecore1" type="text" required placeholder=" " />
                    <label>Noyau 2</label>
                    <input v-model="form.core2" type="text" required placeholder=" " />
                    <label>Noyau Phone 2</label>
                    <input v-model="form.Phonecore2" type="text" required placeholder=" " />
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props:['editFamily'],
    data(){
        return{
            users: [],
            searchUser: '',
            //for search user
            result:"",
            results:[],
            founds:[],
            keyword:"",
            user: null,
            username: '',

            zones:{},
            form:{
                family:'',
                chief:'',
                chiefPhone1:'',
                chiefPhone2:'',
                chiefVice:'',
                chiefVicePhone1:'',
                chiefVicePhone2:'',
                core1:'',
                Phonecore1:'',
                core2:'',
                Phonecore2:'',
                user:'',
                zone:'',
                phone_Followup:0,
                physical_Followup:0,
                no:0,
                phone_FollowupLastWeek:0,
                physical_FollowupLastWeek:0,
                no_FollowupLastWeek:0,
            },
            data_error: {},

            btn:'Enregistrer',
            loading:false
        }
    },
    watch:{
        results(new_val){
            this.founds = new_val
        },
        keyword(new_val){
            new_val = new_val.toLowerCase()
            this.founds = this.results.filter(x =>{
                return JSON.stringify(x).toLowerCase().includes(new_val)
            })
            if(this.founds.length == 1) {
                this.form.user = this.founds[0].id
                this.username = this.founds[0].username
            }
        },
    },
    methods:{
        getFamilyLeader(){
            this.$emit('getFamilyLeader')
        },
        close(){
            this.$emit('close')
        },
        updateUser(id) {
            this.form.user = id;
            this.searchUser = this.filteredUser.find(item => item.id === id)?.username || "";
        },
        getZones(){
                axios.get('/zone',this.headers)
                    .then((res)=>{
                        this.zones = res.data.results
                    })
                    .catch(error => {
                console.log(error)
            })
        },
        save(){
            if(this.editFamily){
                this.loading = true
                let data = new FormData()
                data.append('family',this.form.family)
                data.append('chief',this.form.chief)
                data.append('chiefPhone1',this.form.chiefPhone1)
                data.append('chiefPhone2',this.form.chiefPhone2)
                data.append('chiefVice',this.form.chiefVice)
                data.append('chiefVicePhone1',this.form.chiefVicePhone1)
                data.append('chiefVicePhone2',this.form.chiefVicePhone2)
                data.append('core1',this.form.core1)
                data.append('Phonecore1',this.form.Phonecore1)
                data.append('core2',this.form.core2)
                data.append('Phonecore2',this.form.Phonecore2)
                data.append('user',this.form.user)
                data.append('zone',this.form.zone)
                data.append('phone_Followup',this.form.phone_Followup)
                data.append('physical_Followup',this.form.physical_Followup)
                data.append('no',this.form.no)
                data.append('phone_FollowupLastWeek',this.form.phone_FollowupLastWeek)
                data.append('physical_FollowupLastWeek',this.form.physical_FollowupLastWeek)
                data.append('no_FollowupLastWeek',this.form.no_FollowupLastWeek)
                axios.put('/family/'+this.$store.state.familyOgi.id+'/',this.form,this.headers)
                    .then(()=>{
                    this.loading=false;
                    this.close();
                    this.loading = false
                    this.getFamilyLeader();
                
                    this.$toast.success(`Famille Modifiée`)
                        
                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading=false;
                    })
            }else{
            this.loading = true
                let data = new FormData()
                data.append('family',this.form.family)
                data.append('chief',this.form.chief)
                data.append('chiefPhone1',this.form.chiefPhone1)
                data.append('chiefPhone2',this.form.chiefPhone2)
                data.append('chiefVice',this.form.chiefVice)
                data.append('chiefVicePhone1',this.form.chiefVicePhone1)
                data.append('chiefVicePhone2',this.form.chiefVicePhone2)
                data.append('core1',this.form.core1)
                data.append('Phonecore1',this.form.Phonecore1)
                data.append('core2',this.form.core2)
                data.append('Phonecore2',this.form.Phonecore2)
                data.append('user',this.form.user)
                data.append('zone',this.form.zone)
                data.append('phone_Followup',this.form.phone_Followup)
                data.append('physical_Followup',this.form.physical_Followup)
                data.append('no',this.form.no)
                data.append('phone_FollowupLastWeek',this.form.phone_FollowupLastWeek)
                data.append('physical_FollowupLastWeek',this.form.physical_FollowupLastWeek)
                data.append('no_FollowupLastWeek',this.form.no_FollowupLastWeek)
                axios.post('/family/',this.form,this.headers)
                    .then(()=>{
                    this.loading=false;
                    this.close();
                    this.loading = false
                    this.getFamilyLeader();
                   
                    this.$toast.success(`Famille Ajoutée`)  
                        
                    })
                    .catch(error => {
                console.log(error)
                this.data_error = error.response?.data
                this.$toast.error(error)
                this.loading=false;
                    })
            }
        },  
     
    },
    mounted(){
        this.getZones()
        this.getFamilyLeader()
        
        if(this.editFamily){
            this.btn = 'Modifier'
            this.form.family =this.$store.state.familyOgi.family
            this.form.chief=this.$store.state.familyOgi.chief
            this.form.chiefPhone1=this.$store.state.familyOgi.chiefPhone1
            this.form.chiefPhone2=this.$store.state.familyOgi.chiefPhone2
            this.form.chiefVice=this.$store.state.familyOgi.chiefVice
            this.form.chiefVicePhone1=this.$store.state.familyOgi.chiefVicePhone1
            this.form.chiefVicePhone2=this.$store.state.familyOgi.chiefVicePhone2
            this.form.core1=this.$store.state.familyOgi.core1
            this.form.Phonecore1=this.$store.state.familyOgi.Phonecore1
            this.form.core2=this.$store.state.familyOgi.core2
            this.form.Phonecore2=this.$store.state.familyOgi.Phonecore2
            this.form.user=this.$store.state.familyOgi.user
            this.form.zone = this.$store.state.familyOgi.zone

            this.searchUser = this.$store.state.familyOgi.username
        }
    },
    computed:{
        headers(){
          return {
            headers:{
              "Authorization":"Bearer "+this.$store.state.user.access
            }
          }
        },
        filteredUser() {
            return this.$store.state.users.filter(user =>
                user.username.toLowerCase().includes(this.searchUser.toLowerCase()) ||
                user.role.toLowerCase().includes(this.searchUser.toLowerCase())
            );
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped>
</style>