<!-- App.vue -->

<template>
  <div id="app">
    <OurDashboard />
  </div>
</template>

<script>
import OurDashboard from '@/components/OurDashboard.vue';

export default {
  name: 'App',
  components: {
    OurDashboard,
  },
};
</script>

<style>
/* Add global styles here if needed */
</style>
