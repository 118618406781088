<template>
    <div class="content-ogi">


        <div class="parent-table">
            <h3>Les Hôtes des cellules</h3>
            <div class="header-table">
                <button @click="modal = true, modify = false"><i class="fas fa-plus"></i> Ajouter</button>
                <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
                <div class="search">
                    <input type="text" placeholder="Rechercher..." v-model="searchText">
                    <button class="btn-action">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>

            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nom et Prénom</th>
                            <th>phone</th>
                            <th>Adresse</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="filteredCellHost.length > 0">
                        <tr v-for="item in filteredCellHost" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.lastName }} {{ item.firstName }}</td>
                            <td class="t-colomn">
                                <span><a :href="'tel:' + item.phone1">{{ item.phone1 }}</a></span>
                                <span><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a></span>
                            </td>
                            <td>{{ item.address }}</td>
                            <td>
                                <button class="btn-action" @click="editCellHost(item)"> <i
                                        class="fas fa-edit"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td>Pas de Resultat disponible</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <addcellhost :editCellHost="modify" @getCellHost="getCellHost" @close="close" v-if="modal">
            </addcellhost>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import addcellhost from '@/components/Cell_Host/cellhost_modal.vue'


export default {
    name: 'periodPageZ',
    components: {
        // moment,
        addcellhost,
    },
    data() {
        return {
            result: '',
            modal: false,
            modify: false,
            isModalOpen: false,
            searchText: "",

        };
    },
    methods: {
        close() {
            this.modal = false
        },
        editCellHost(item) {
            this.modal = true
            this.modify = true
            this.$store.state.cellHost = item
        },
        openModal() {
            this.isModalOpen = !this.isModalOpen;
        },
        exportExcel() {
            console.log(this.filteredCellHost)
            const workSheet = XLSX.utils.json_to_sheet(this.filteredCellHost)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'cellHost.xlsx')
        }
    },
    computed: {
        filteredCellHost() {
            return this.searchInArray(this.$store.state.cellHosts, this.searchText);
        },
    },
    mounted() {
    }
};
</script>

<style>
.card-modal {
    width: 700px;
    height: 100px;
}
</style>