<template>
    <div class="page-our-account">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />

        <div class="column is-12">
            <h1 class="compteTitle">Compte</h1>
        </div>

        <br>

        <strong v-if="$store.state.user.role === 'Member'">Member</strong>


        <br>
        <br>
        <br>

        <hr>


        <div class="buttons">
            <div class="buttons">
                <button class="logout" @click="confirmLogout"><i class="fas fa-sign-out-alt"> Deconnexion</i></button>
            </div>
        </div>

        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
import store from '@/store'

export default {
    name: 'OurAccount',

    methods: {
        confirmLogout() {
            if (confirm('Voulez-vous vraiment vous deconnecter?')) {
                this.logout();
            }
        },
        logout() {
            this.$router.push('/');
            localStorage.removeItem('user');
            store.dispatch('setLogged_in', false);
        },
        getCellLeader() {
            this.axios.get('/logged-cell/', this.getHeaders()
            ).then(response => {
                this.$store.state.cell = response.data.results;
                this.$store.state.cellOgi = this.$store.state.cell[0].cell
            })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getCellLeader();
        this.$store.state.user = JSON.parse(localStorage.getItem('user'))
    }
}
</script>
<style>

</style>