<template>
    <div class="parent-table">
        <h3>Les communications</h3>
        <div class="header-table">
            <button @click="exportExcel()"><i class="fas fa-file-export"></i> Excel</button>
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <div class="search">
                <input type="text" placeholder="Rechercher..." v-model="searchText" @keydown.enter="search">
                <button class="btn-action" @click="search">
                    <i class="fas fa-search"></i>
                </button>
                <p>{{ result }}</p>
            </div>
            <button @click=searchPage(searchQuery)>Appliquer</button>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Envoyée par</th>
                        <th>Communication</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody v-if="filteredData.length > 0">
                    <tr v-for="item in filteredData" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.sender }}</td>
                        <td>{{ item.notification }}</td>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import moment from 'moment'

export default {
    name: 'CommunicationPageZ',
    components: {
    },
    data() {
        return {
            result: '',
            moment: moment,
            searchText: "",

            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
        };
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getCommunication(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getCommunication(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getCommunication(url = null) {
            if (!url) {
                url = '/communication/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.communication = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getCommunication(`/communication/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        search() {
            this.result = "recherche..."
            this.axios.get(`/communication/?search=${this.searchText}`, this.getHeaders())
                .then((response) => {
                    this.$store.state.communication = response.data.results
                    this.result = ""
                }).catch((error) => {
                    console.log(error)
                })
        },
        exportExcel() {
            const workSheet = XLSX.utils.json_to_sheet(this.filteredData)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
            XLSX.writeFile(workBook, 'communication.xlsx')
        }
    },
    computed: {
        filteredData() {
            return this.searchInArray(this.$store.state.communication, this.searchText);
        },
    },
    mounted() {
        this.getCommunication();

    }
};
</script>

<style></style>