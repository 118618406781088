<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Département</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Taper et selectioner l'utilisateur:</label>
                    <input placeholder="Taper et selectioner l'utilisateur" type="text" v-model="searchUser">
                    <div class="t-colomn">
                        <div v-for="item in filteredUser.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.user" />
                            <label v-if="!!searchUser" :for="item.id" @click="updateUser(item.id)">
                                {{ item.username }}, {{ item.role }}
                            </label>
                        </div>
                    </div>
                    <label class="placeholder">Objectif</label>
                    <select class="input" v-model="form.objective" name="" id="">
                        <option selected value="" disabled>--------</option>
                        <option v-for="obj in objectives" :key="obj.id" :value="obj.id">{{ obj.objective }}
                        </option>
                    </select>
                    <small v-for="err in data_error?.objective" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Categorie</label>
                    <select class="input" v-model="form.category" name="" id="">
                        <option selected value="" disabled>--------</option>
                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat.category }}</option>
                    </select>
                    <small v-for="err in data_error?.category" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Department</label>
                    <input class="input" v-model="form.name" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.name" :key="err.id">
                        {{ err }}
                    </small>
                    <label class="placeholder">Chef</label>
                    <input class="input" v-model="form.chief" type="text" required placeholder=" " />
                    <label class="placeholder">Chef Phone 1</label>
                    <input class="input" v-model="form.chiefPhone1" type="text" required placeholder=" " />
                    <label class="placeholder">Chef Phone 2</label>
                    <input class="input" v-model="form.chiefPhone2" type="text" required placeholder=" " />
                    <label class="placeholder">Vice Chef</label>
                    <input class="input" v-model="form.chiefVice" type="text" required placeholder=" " />
                    <label class="placeholder">Vice Chef Phone 1</label>
                    <input class="input" type="text" v-model="form.chiefVicePhone1" required placeholder=" " />
                    <label class="placeholder">Vice Chef Phone 2</label>
                    <input v-model="form.chiefVicePhone2" class="input" type="text" required placeholder=" " />
                    <label class="placeholder">Noyau 1</label>
                    <input v-model="form.core1" class="input" type="text" required placeholder=" " />
                    <label class="placeholder">Noyau 1 Phone </label>
                    <input v-model="form.Phonecore1" class="input" type="text" required placeholder=" " />
                    <label class="placeholder">Noyau 2</label>
                    <input v-model="form.core2" class="input" type="text" required placeholder=" " />
                    <label class="placeholder">Noyau 2 phone</label>
                    <input class="input" type="text" v-model="form.Phonecore2" required placeholder=" " />
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['edit_dep'],
    data() {
        return {
            searchUser: '',
            //for search user
            result: "",
            results: [],
            founds: [],
            keyword: "",
            user: null,
            username: '',

            form: {
                name: '',
                chief: '',
                chiefPhone1: '',
                chiefPhone2: '',
                chiefVice: '',
                chiefVicePhone1: '',
                chiefVicePhone2: '',
                core1: '',
                Phonecore1: '',
                core2: '',
                Phonecore2: '',
                category: '',
                objective: '',
                user: '',

            },
            categories: [],
            objectives: [],
            users: [],
            data_error: {},

            btn: 'Enregistrer',
            loading: false
        }
    },
    watch: {
        results(new_val) {
            this.founds = new_val
        },
        keyword(new_val) {
            new_val = new_val.toLowerCase()
            this.founds = this.results.filter(x => {
                return JSON.stringify(x).toLowerCase().includes(new_val)
            })
            if (this.founds.length == 1) {
                this.form.user = this.founds[0].id
                this.username = this.founds[0].username
            }
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getDepartment() {
            this.$emit('getDepartment')
        },
        updateUser(id) {
            this.form.user = id;
            this.searchUser = this.filteredUser.find(item => item.id === id)?.username || "";
        },
        save() {
            if (this.edit_dep) {
                this.loading = true
                let data = new FormData()
                data.append('name', this.form.name)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('category', this.form.category)
                data.append('objective', this.form.objective)
                data.append('user', this.form.user)

                axios.put('/department/' + this.$store.state.department.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getDepartment()
                        this.$toast.success(`Departement Modifié`)

                    })
                    .catch(error => {
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('name', this.form.name)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('category', this.form.category)
                data.append('objective', this.form.objective)
                data.append('user', this.form.user)
                axios.post('/department/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getDepartment()
                        this.$toast.success(`Departement Ajouté`)

                    })
                    .catch(error => {
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        getCategory() {
            axios.get('/category', this.headers)
                .then((res) => {
                    this.categories = res.data.results
                })
                .catch(error => {
                    this.$toast.error(error)
                    console.log(error)
                })
        },
        getObjectives() {
            axios.get('/objective', this.headers)
                .then((res) => {
                    this.objectives = res.data.results
                })
                .catch(error => {
                    this.$toast.error(error)
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getCategory()
        this.getDepartment()
        this.getObjectives()
        
        if (this.edit_dep) {
            this.form.name = this.$store.state.department.name
            this.form.chief = this.$store.state.department.chief
            this.form.chiefPhone1 = this.$store.state.department.chiefPhone1
            this.form.chiefPhone2 = this.$store.state.department.chiefPhone2
            this.form.chiefVice = this.$store.state.department.chiefVice
            this.form.chiefVicePhone1 = this.$store.state.department.chiefVicePhone1
            this.form.chiefVicePhone2 = this.$store.state.department.chiefVicePhone2
            this.form.Phonecore1 = this.$store.state.department.Phonecore1
            this.form.core2 = this.$store.state.department.core2
            this.form.Phonecore2 = this.$store.state.department.Phonecore2
            this.form.category = this.$store.state.department.category
            this.form.objective = this.$store.state.department.objective
            this.form.user = this.$store.state.department.user
            this.searchUser = this.$store.state.department.username
            this.btn = 'Modifier'
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        },
        filteredUser() {
            return this.$store.state.users.filter(user =>
                user.username.toLowerCase().includes(this.searchUser.toLowerCase()) ||
                user.role.toLowerCase().includes(this.searchUser.toLowerCase())
            );
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>