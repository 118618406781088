import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


// import mixins from './mixins'
import mixins from '@/mixin/axiosMixin.js';

import Toaster from "@meforma/vue-toaster";
// import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/styles.css'
import './assets/css/blog.css'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'

// import Chart from 'chart.js';



library.add(fas)

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

axios.defaults.baseURL = 'https://app.minevam-kigobe.org/api'




// Interceptor to handle CSRF token errors
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 403) {
            // Handle CSRF token error here, for example, by refreshing the page
            window.location.reload();
        }
        return Promise.reject(error);
    }
);
// Event listener to include CSRF token in Axios requests
document.addEventListener('DOMContentLoaded', () => {
    const csrfTokenInput = document.querySelector('input[name="csrfmiddlewaretoken"]');

    if (csrfTokenInput) {
        axios.defaults.headers.common['X-CSRFToken'] = csrfTokenInput.value;
    }
});

const app = createApp(App)


// app.config.globalProperties.axios =  { ...axiosInstance }
app.config.globalProperties.axios = axios //
app.component('font-awesome-icon', FontAwesomeIcon)

app
    .use(store)
    .use(router)
    .use(Toaster)
    .mixin(mixins)
    .mount('#app') // ija musi yizindi zose
