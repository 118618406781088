<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />

  <router-view />
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {}
  },
  components: {},
  methods: {},
  mounted() {
    this.$store.state.user = JSON.parse(localStorage.getItem('user'))
  }


})
</script>


<style>
:root {
  --bg: #f9f9f9;
  --gray_p: gray;
  --gray_eee: #eee;
  --form_bg: #ddd;
  --white: #fff;
  --txt_color: #2a2a2e;
  --red: #e74a3b;
  --orange: #f5983a;

  --btn_blue: #c5d9fb;
  --btn_bg: #4285f4;
  --btn_bg_hover: #0056b3;

  --btn_color: #fff;
  --btn_color_hover: #fff;
  --sidebar_bg: #2c3e50;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #f5983a;
  border-radius: 10px;
}

.moveInUp-enter-active {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* /////// */
input[type="text"],
input[type="password"],
button {
  padding: 5px 8px;
  border-radius: 5px;
  border: 0;
  -wbkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

input[type="file"] {
  display: none;
}

input {
  padding: 8px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  margin: 0;
  background-color: var(--form_bg);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

button {
  background-color: var(--btn_bg);
  color: var(--btn_color);
  cursor: pointer;
}

button:hover {
  background-color: var(--btn_bg_hover);
  color: var(--btn_color_hover);
}
</style>
