<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Cellule</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label>Taper et selectioner l'utilisateur:</label>
                    <input placeholder="Taper et selectioner l'utilisateur" type="text" v-model="searchUser">
                    <small v-for="err in data_error?.user" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn">
                        <div v-for="item in filteredUser.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.user" />
                            <label v-if="!!searchUser" :for="item.id" @click="updateUser(item.id)">
                                {{ item.username }}, {{ item.role }}
                            </label>
                        </div>
                    </div>
                    <label>Hôte de Cellule:</label>
                    <input placeholder="Taper et selectioner l'hôte de Cellule" type="text" v-model="searchCellHost">
                    <small v-for="err in data_error?.cellHost" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn">
                        <div v-for="item in filteredCellHost.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.cellHost" />
                            <label v-if="!!searchCellHost" :for="item.id" @click="updateCellHost(item.id)">
                                {{ item.firstName }} {{ item.lastName }}, {{ item.address }}
                            </label>
                        </div>
                    </div>
                    <label>Famille:</label>
                    <input placeholder="Taper et selectioner la Famille" type="text" v-model="searchFamily">
                    <small v-for="err in data_error?.family" :key="err.id">
                        {{ err }}
                    </small>
                    <div class="t-colomn">
                        <div v-for="item in filteredFamily.slice(0, 8)" :key="item.id">
                            <input class="input-radio-search" type="radio" :id="item.id" :value="item.id"
                                v-model="form.family" />
                            <label v-if="!!searchFamily" :for="item.id" @click="updateFamily(item.id)">
                                {{ item.family }}
                            </label>
                        </div>
                    </div>
                    <label>Cellule:</label>
                    <input v-model="form.cell" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.cell" :key="err.id">
                        {{ err }}
                    </small>
                    <label>Chef:</label>
                    <input v-model="form.chief" type="text" required placeholder=" " />
                    <label>Numero Chef 1:</label>
                    <input v-model="form.chiefPhone1" type="text" required placeholder=" " />
                    <label>Numero Chef 2:</label>
                    <input v-model="form.chiefPhone2" type="text" required placeholder=" " />
                    <label>Vice Chef:</label>
                    <input v-model="form.chiefVice" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 1:</label>
                    <input v-model="form.chiefVicePhone1" type="text" required placeholder=" " />
                    <label>Vice Chef Numero 2:</label>
                    <input v-model="form.chiefVicePhone2" type="text" required placeholder=" " />
                    <label>Noyau 1:</label>
                    <input v-model="form.core1" type="text" required placeholder=" " />
                    <label>Noyau Phone 1:</label>
                    <input v-model="form.Phonecore1" type="text" required placeholder=" " />
                    <label>Noyau 2</label>
                    <input v-model="form.core2" type="text" required placeholder=" " />
                    <label>Noyau Phone 2:</label>
                    <input v-model="form.Phonecore2" type="text" required placeholder=" " />
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['editCell'],
    data() {
        return {
            users: [],
            searchUser: '',
            family: [],
            searchFamily: '',
            cellHosts: [],
            searchCellHost: '',
            //for search user
            result: "",
            results: [],
            founds: [],
            keyword: "",
            user: null,
            username: '',

            //for search CellHost
            resultCellHost: "",
            resultsCellHost: [],
            foundsCellHost: [],
            keywordCellHost: "",
            cellHost: null,
            firstName: '',
            lastName: '',
            address: '',

            families: [],
            form: {
                cell: '',
                chief: '',
                chiefPhone1: '',
                chiefPhone2: '',
                chiefVice: '',
                chiefVicePhone1: '',
                chiefVicePhone2: '',
                core1: '',
                Phonecore1: '',
                core2: '',
                Phonecore2: '',
                user: '',
                cellHost: '',
                family: ''
            },
            data_error: {},
            btn: 'Enregistrer',
            loading: false
        }
    },
    watch: {
        results(new_val) {
            this.founds = new_val
        },
        keyword(new_val) {
            new_val = new_val.toLowerCase()
            this.founds = this.results.filter(x => {
                return JSON.stringify(x).toLowerCase().includes(new_val)
            })
            if (this.founds.length == 1) {
                this.form.user = this.founds[0].id
                this.username = this.founds[0].username
            }
        },
        // user(new_val){
        //     this.result = `${new_val.username}`
        //     // this.$emit("found", new_val)
        // },
        resultsCellHost(new_val_CellHost) {
            this.foundsCellHost = new_val_CellHost
        },
        keywordCellHost(new_val_CellHost) {
            new_val_CellHost = new_val_CellHost.toLowerCase()
            this.foundsCellHost = this.resultsCellHost.filter(x => {
                return JSON.stringify(x).toLowerCase().includes(new_val_CellHost)
            })
            if (this.foundsCellHost.length == 1) {
                this.form.cellHost = this.foundsCellHost[0].id
            }
        },
        // userCellHost(new_val_CellHost){
        //     this.result = `${new_val_CellHost.lastName}`
        //     // this.$emit("found", new_val_CellHost)
        // }
    },
    methods: {
        getCells() {
            this.$emit('getCells')
        },
        close() {
            this.$emit('close')
        },
        updateFamily(id) {
            this.form.family = id;
            this.searchFamily = this.filteredFamily.find(item => item.id === id)?.family || "";
        },
        updateCellHost(id) {
            this.form.cellHost = id;
            this.searchCellHost = this.filteredCellHost.find(item => item.id === id)?.firstName || "";
        },
        updateUser(id) {
            this.form.user = id;
            this.searchUser = this.filteredUser.find(item => item.id === id)?.username || "";
        },
        save() {
            if (this.editCell) {
                this.loading = true
                let data = new FormData()
                data.append('cell', this.form.cell)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('user', this.form.user)
                data.append('cellHost', this.form.cellHost)
                data.append('family', this.form.family)
                axios.put('/cell/' + this.$store.state.cellOgi.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.loading = false
                        this.getCells();

                        this.$toast.success(`Cellule Modifiée`)
                    })
                    .catch(error => {
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('cell', this.form.cell)
                data.append('chief', this.form.chief)
                data.append('chiefPhone1', this.form.chiefPhone1)
                data.append('chiefPhone2', this.form.chiefPhone2)
                data.append('chiefVice', this.form.chiefVice)
                data.append('chiefVicePhone1', this.form.chiefVicePhone1)
                data.append('chiefVicePhone2', this.form.chiefVicePhone2)
                data.append('core1', this.form.core1)
                data.append('Phonecore1', this.form.Phonecore1)
                data.append('core2', this.form.core2)
                data.append('Phonecore2', this.form.Phonecore2)
                data.append('user', this.form.user)
                data.append('cellHost', this.form.cellHost)
                data.append('family', this.form.family)
                axios.post('/cell/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.loading = false
                        this.getCells();
                        this.$toast.success(`Cellule Ajoutée`)
                    })
                    .catch(error => {
                        this.data_error = error.response?.data
                        this.loading = false;
                        this.$toast.error(error, "error")
                    })
            }
        },
    },
    mounted() {
        this.getCells()

        if (this.editCell) {
            this.btn = 'Modifier'
            this.form.cell = this.$store.state.cellOgi.cell
            this.form.chief = this.$store.state.cellOgi.chief
            this.form.chiefPhone1 = this.$store.state.cellOgi.chiefPhone1
            this.form.chiefPhone2 = this.$store.state.cellOgi.chiefPhone2
            this.form.chiefVice = this.$store.state.cellOgi.chiefVice
            this.form.chiefVicePhone1 = this.$store.state.cellOgi.chiefVicePhone1
            this.form.chiefVicePhone2 = this.$store.state.cellOgi.chiefVicePhone2
            this.form.core1 = this.$store.state.cellOgi.core1
            this.form.Phonecore1 = this.$store.state.cellOgi.Phonecore1
            this.form.core2 = this.$store.state.cellOgi.core2
            this.form.Phonecore2 = this.$store.state.cellOgi.Phonecore2
            this.form.user = this.$store.state.cellOgi.user
            this.form.family = this.$store.state.cellOgi.family
            this.form.cellHost = this.$store.state.cellOgi.cellHost

            this.searchUser = this.$store.state.cellOgi.username
            this.searchCellHost = this.$store.state.cellOgi.cellHost_name
            this.searchFamily = this.$store.state.cellOgi.family_name
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        },
        filteredUser() {
            return this.$store.state.users.filter(user =>
                user.username.toLowerCase().includes(this.searchUser.toLowerCase()) ||
                user.role.toLowerCase().includes(this.searchUser.toLowerCase())
            );
        },
        filteredCellHost() {
            return this.$store.state.cellHosts.filter(cellHost =>
                cellHost.firstName.toLowerCase().includes(this.searchCellHost.toLowerCase()) ||
                cellHost.lastName.toLowerCase().includes(this.searchCellHost.toLowerCase())
            );
        },
        filteredFamily() {
            return this.$store.state.family.filter(family =>
                family.family.toLowerCase().includes(this.searchFamily.toLowerCase()) ||
                family.chief.toLowerCase().includes(this.searchFamily.toLowerCase())
            );
        }

    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>