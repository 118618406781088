<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Categorie</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label for="firstname" class="placeholder">Category</label>
                    <input id="firstname" v-model="form.category" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.category" :key="err.id">
                        {{ err }}
                    </small>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['editCategory'],
    data() {
        return {
            form: {
                category: '',
            },
            data_error: {},

            btn: 'Enregistrer',
            loading: false,
        }
    },
    methods: {
        save() {
            if (this.editCategory) {
                this.loading = true
                let data = new FormData()
                data.append('category', this.form.category)
                axios.put('/category/' + this.$store.state.category.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getCategory()
                        this.$toast.success(`Categorie Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('category', this.form.category)
                axios.post('/category/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getCategory()
                        this.$toast.success(`Categorie ajouté`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        close() {
            this.$emit('close')
        },
        getCategory() {
            this.$emit('getCategory')
        }
    },
    mounted() {
        this.getCategory()
        if (this.editCategory) {
            this.btn = 'Modifier'
            this.form.category = this.$store.state.category.category
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>