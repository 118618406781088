import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
// import store from '../store';


import RegistrationView from '../views/RegistrationView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'

///// For CellDashboard 
import DashboardPage from '../views/Cell/DashboardPage.vue';
import AjouterRapportPage from '../views/Cell/AjouterRapportPage.vue';
import MemberPage from '../views/Cell/MemberPage.vue';
import PresencePage from '../views/Cell/PresencePage.vue';
import RapportPage from '../views/Cell/RapportPage.vue';
import CommunicationPage from '../views/Cell/CommunicationPage.vue';
import AccountPage from '../views/Cell/AccountPage.vue';

///// For FamilyDashboard 
import DashboardPagef from '../views/Family/DashboardPagef.vue';
import AjouterRapportPagef from '../views/Family/AjouterRapportPagef.vue';
import PresencePagef from '../views/Family/PresencePagef.vue';
import RapportPagef from '../views/Family/RapportPagef.vue';
import CommunicationPagef from '../views/Family/CommunicationPagef.vue';
import AccountPagef from '../views/Family/AccountPagef.vue';

///// For ZoneDashboard 
import DashboardPageZ from '../views/Zone/DashboardPageZ.vue';
import AjouterRapportPageZ from '../views/Zone/AjouterRapportPageZ.vue';
import PresencePageZ from '../views/Zone/PresencePageZ.vue';
import RapportPageZ from '../views/Zone/RapportPageZ.vue';
import ObservationPageZ from '../views/Zone/ObservationPageZ.vue';
import CommunicationPageZ from '../views/Zone/CommunicationPageZ.vue';
import AccountPageZ from '../views/Zone/AccountPageZ.vue';


///// For DistrictDashboard 
import DashboardPageD from '../views/District/DashboardPageD.vue';
import AjouterRapportPageD from '../views/District/AjouterRapportPageD.vue';
import PresencePageD from '../views/District/PresencePageD.vue';
import RapportPageD from '../views/District/RapportPageD.vue';
import ObservationPageD from '../views/District/ObservationPageD.vue';
import CommunicationPageD from '../views/District/CommunicationPageD.vue';
import AccountPageD from '../views/District/AccountPageD.vue';


///// For StaffDashboard 
import DashboardPageS from '../views/Staff/DashboardPageS.vue';
import UserPageS from '../views/Staff/UserPageS.vue';
import CommunicationPageS from '../views/Staff/CommunicationPageS.vue';

import PeriodPageS from '../views/Staff/PeriodPageS.vue';
import MemberPageS from '../views/Staff/MemberPageS.vue';

import DistrictPageS from '../views/Staff/DistrictPageS.vue';
import RapportDistrictPageS from '../views/Staff/RapportDistrictPageS.vue';

import ZonePageS from '../views/Staff/ZonePageS.vue';
import RapportZonePageS from '../views/Staff/RapportZonePageS.vue';

import FamilyPageS from '../views/Staff/FamilyPageS.vue';
import RapportFamilyPageS from '../views/Staff/RapportFamilyPageS.vue';

import CellHostPageS from '../views/Staff/CellHostPageS.vue';
import CellPageS from '../views/Staff/CellPageS.vue';
import RapportCellPageS from '../views/Staff/RapportCellPageS.vue';

import IncomePageS from '../views/Staff/IncomePageS.vue';
import OutcomeTypePageS from '../views/Staff/OutcomeTypePageS.vue';
import BeneficiaryPageS from '../views/Staff/BeneficiaryPageS.vue';
import OutcomePageS from '../views/Staff/OutcomePageS.vue';

import DepartmentPageS from '../views/Staff/DepartmentPageS.vue';
import DepartmentMemberPageS from '../views/Staff/DepartmentMemberPageS.vue';

import CategoryPageS from '../views/Staff/CategoryPageS.vue';
import ObjectivePageS from '../views/Staff/ObjectivePageS.vue';

import AccountPageS from '../views/Staff/AccountPageS.vue';



import DashboardPageM from '../views/Member/DashboardPageM.vue';
import AccountPageM from '../views/Member/AccountPageM.vue';

//////// BLOG /////////
import ArticlesView from '../views/Blog/ArticlesView.vue'
import ArticleDetailView from '../views/Blog/ArticleDetailView.vue'
import ArticlePhotosView from '../views/Blog/ArticlePhotosView.vue'


//////
import PathNotFoundView from '../views/PathNotFoundView.vue'



const routes = [
  {
    path: '/',
    name: 'home', component: LoginView,
  },
  {
    path: '/kwiyandikisha',
    name: 'RegistrationView', 
    component: RegistrationView
  },
  {
    path: '/dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
    children: [
      { path: 'cell', name: 'cell', component: DashboardPage, },
      { path: 'cell-ajouterRapport', name: 'cell-ajouterRapport', component: AjouterRapportPage, },
      { path: 'cell-member', name: 'cell-member', component: MemberPage, },
      { path: 'cell-presence', name: 'cell-presence', component: PresencePage, },
      { path: 'cell-rapport', name: 'cell-rapport', component: RapportPage, },
      { path: 'cell-communication', name: 'cell-communication', component: CommunicationPage, },
      { path: 'cell-account', name: 'cell-account', component: AccountPage, },
      { path: 'family', name: 'family', component: DashboardPagef, },
      { path: 'family-ajouterRapport', name: 'family-ajouterRapport', component: AjouterRapportPagef, },
      { path: 'family-presence', name: 'family-presence', component: PresencePagef, },
      { path: 'family-rapport', name: 'family-rapport', component: RapportPagef, },
      { path: 'family-communication', name: 'family-communication', component: CommunicationPagef, },
      { path: 'family-account', name: 'family-account', component: AccountPagef, },
      { path: 'zone', name: 'zone', component: DashboardPageZ, },
      { path: 'zone-ajouterRapport', name: 'zone-ajouterRapport', component: AjouterRapportPageZ, },
      { path: 'zone-presence', name: 'zone-presence', component: PresencePageZ, },
      { path: 'zone-rapport', name: 'zone-rapport', component: RapportPageZ, },
      { path: 'zone-observation', name: 'zone-observation', component: ObservationPageZ, },
      { path: 'zone-communication', name: 'zone-communication', component: CommunicationPageZ, },
      { path: 'zone-account', name: 'zone-account', component: AccountPageZ, },
      { path: 'district', name: 'district', component: DashboardPageD, },
      { path: 'district-ajouterRapport', name: 'district-ajouterRapport', component: AjouterRapportPageD, },
      { path: 'district-presence', name: 'district-presence', component: PresencePageD, },
      { path: 'district-rapport', name: 'district-rapport', component: RapportPageD, },
      { path: 'district-observation', name: 'district-observation', component: ObservationPageD, },
      { path: 'district-communication', name: 'district-communication', component: CommunicationPageD, },
      { path: 'district-account', name: 'district-account', component: AccountPageD, },
      { path: 'staff', name: 'staff', component: DashboardPageS, },
      { path: 'staff-user', name: 'staff-user', component: UserPageS, },
      { path: 'staff-communication', name: 'staff-communication', component: CommunicationPageS, },
      { path: 'staff-period', name: 'staff-period', component: PeriodPageS, },
      { path: 'staff-member', name: 'staff-member', component: MemberPageS, },
      { path: 'staff-district', name: 'staff-district', component: DistrictPageS, },
      { path: 'staff-rapportDistrict', name: 'staff-rapportDistrict', component: RapportDistrictPageS, },
      { path: 'staff-zone', name: 'staff-zone', component: ZonePageS, },
      { path: 'staff-rapportZone', name: 'staff-rapportZone', component: RapportZonePageS, },
      { path: 'staff-family', name: 'staff-family', component: FamilyPageS, },
      { path: 'staff-rapportFamily', name: 'staff-rapportFamily', component: RapportFamilyPageS, },
      { path: 'staff-cellHost', name: 'staff-cellHost', component: CellHostPageS, },
      { path: 'staff-cell', name: 'staff-cell', component: CellPageS, },
      { path: 'staff-rapportCell', name: 'staff-rapportCell', component: RapportCellPageS, },
      { path: 'staff-income', name: 'staff-income', component: IncomePageS, },
      { path: 'staff-outcomeType', name: 'staff-outcomeType', component: OutcomeTypePageS, },
      { path: 'staff-beneficiary', name: 'staff-beneficiary', component: BeneficiaryPageS, },
      { path: 'staff-outcome', name: 'staff-outcome', component: OutcomePageS, },
      { path: 'staff-department', name: 'staff-department', component: DepartmentPageS, },
      { path: 'staff-departmentMember', name: 'staff-departmentMember', component: DepartmentMemberPageS, },
      { path: 'staff-category', name: 'staff-category', component: CategoryPageS, },
      { path: 'staff-objective', name: 'staff-objective', component: ObjectivePageS, },
      { path: 'staff-account', name: 'staff-account', component: AccountPageS, },

      { path: 'member', name: 'member', component: DashboardPageM, },
      { path: 'member-account', name: 'member-account', component: AccountPageM, },


    ],
  },
  {
    path: '/blog/articles',
    name: 'articles',
    component: ArticlesView
  },
  {
    path: '/blog/article/:id',
    name: 'article',
    component: ArticleDetailView
  },
  {
    path: '/blog/photos/:id',
    name: 'photos',
    component: ArticlePhotosView
  },

  {
    path: '/:pathMatch(.*)*', component: PathNotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = JSON.parse(localStorage.getItem('user'))

  if (requiresAuth && !user) {
    next('/');
  } else if (requiresAuth && user) {
    const accessToken = user?.access;
    if (accessToken) {
      const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
      const expirationTime = decodedToken.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime < currentTime) {
        localStorage.removeItem('user');
        store.dispatch('setLogged_in', false);
      } else {
        store.dispatch('setLogged_in', true);
        next();
      }
    }
  } else {
    next();
  }
});


export default router


