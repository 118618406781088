<template>
    <div class="container-modal">
        <div class="content-modal">
            <div class="title-modal">
                <h5>Objectif</h5>
                <button class="backBash" @click="close">&times;</button>
            </div>
            <div class="content">
                <form class="form">
                    <label class="placeholder">Objectif</label>
                    <input v-model="form.objective" type="text" required placeholder=" " />
                    <small v-for="err in data_error?.objective" :key="err.id">
                        {{ err }}
                    </small>
                </form>
            </div>
            <div class="buttons">
                <button @click="save" type="submit">{{ loading ? "Chargement..." : btn }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['editObjective'],
    data() {
        return {
            form: {
                objective: '',
            },
            data_error: {},

            btn: 'Enregistrer',
            loading: false,
        }
    },
    methods: {
        save() {
            if (this.editObjective) {
                this.loading = true
                let data = new FormData()
                data.append('objective', this.form.objective)
                axios.put('/objective/' + this.$store.state.objective.id + '/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getObjective()
                        this.$toast.success(`objective Modifié`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            } else {
                this.loading = true
                let data = new FormData()
                data.append('objective', this.form.objective)
                axios.post('/objective/', this.form, this.headers)
                    .then(() => {
                        this.loading = false;
                        this.close();
                        this.getObjective()
                        this.$toast.success(`Objectif ajouté`)

                    })
                    .catch(error => {
                        console.log(error)
                        this.data_error = error.response?.data
                        this.$toast.error(error)
                        this.loading = false;
                    })
            }
        },
        close() {
            this.$emit('close')
        },
        getObjective() {
            this.$emit('getObjective')
        }
    },
    mounted() {
        this.getObjective()
        if (this.editObjective) {
            this.btn = 'Modifier'
            this.form.objective = this.$store.state.objective.objective
        }
    },
    computed: {
        headers() {
            return {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.user.access
                }
            }
        }
    }
}
</script>
<style src="../../assets/css/modal/modal.css" scoped></style>