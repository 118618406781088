<template>
    <div class="parent-table">
        <div class="filter_pr">
            <h3>Les Présences des membres</h3>
        </div>
        <div class="filter_pr">
            <div class="filter_pr_date">Du <input type="date" v-model="date_from"></div>
            <div class="filter_pr_date">au <input type="date" v-model="date_to"></div>
            <button @click="getCellMemberAttendance()">Trier</button>
        </div>
        <div class="filter_pr">
            <div class="card-pourcentage-1">
                <div class="card-body">
                    <p class="card-pr">Présence</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.presence_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.presence_percentage }}%</div>
                    </div>
                </div>
            </div>
            <div class="card-pourcentage-3">
                <div class="card-body">
                    <p class="card-pr">Justifié</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.justify_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.justify_percentage }}%</div>
                    </div>
                </div>
            </div>
            <div class="card-pourcentage-2">
                <div class="card-body">
                    <p class="card-pr">Absence</p>
                    <div class="card_total">
                        <div>Total: {{ this.$store.state.memberAttendance.absence_total }}</div>
                        <div>|</div>
                        <div>{{ this.$store.state.memberAttendance.absence_percentage }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom et Prénom </th>
                        <th>Présence</th>
                        <th>Observation</th>
                        <th>Accompagnement</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in this.$store.state.memberAttendance.results" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.member_name }}</td>
                        <td v-if="item.attendance === 'present'">Présent</td>
                        <td v-else-if="item.attendance === 'absent'">Absent</td>
                        <td v-else>Justifié</td>
                        <td>{{ item.observation }}</td>
                        <td v-if="item.Followup === 'phone_Followup'">Par téléphone</td>
                        <td v-else-if="item.Followup === 'physical_Followup'">Physique</td>
                        <td v-else>no</td>
                        <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <button @click="decrementPage" :disabled="currentPage === 1">&lt;</button>
                <button v-for="pageNumber in displayedPages" :key="pageNumber"
                    @click="getCellMemberAttendance(pageNumber)" :class="{ active: currentPage === pageNumber }">{{
                pageNumber }}</button>
                <span v-if="totalPages > displayedPages[displayedPages.length - 1]">...</span>
                <button v-if="!displayedPages.includes(totalPages)" @click="getCellMemberAttendance(totalPages)">
                    {{ totalPages }}
                </button>
                <button @click="incrementPage" :disabled="currentPage === totalPages">&gt;</button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            moment: moment,
            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,
            
            date_from: null,
            date_to: null
        };
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getCellMemberAttendance(this.currentPage); // Pass current page number
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getCellMemberAttendance(this.currentPage); // Pass current page number
            }
        },
        getCellMemberAttendance(pageNumber = null) {
            let url = `/cell-member-attendance/?date_range=${this.date_from},${this.date_to}`;
            if (pageNumber !== null) {
                url += `&page=${pageNumber}`;
                this.currentPage = pageNumber;
            } else {
                url;
            }

            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.memberAttendance = response.data;
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        displayedPages() {
            const rangeStart = Math.max(1, this.currentPage - 1);
            const rangeEnd = Math.min(this.totalPages, this.currentPage + 1);
            return Array.from({ length: rangeEnd - rangeStart + 1 }, (_, i) => i + rangeStart);
        },
    },
    mounted() {
        this.getCellMemberAttendance();
    },
};
</script>

<style></style>