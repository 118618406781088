<template>
    <div class="parent-table">
        <h3>Les Présences des membres dans les cellules</h3>
        <div class="header-table">
            <input type="text" placeholder="rechercher..." v-model="searchText">
            <input type="text" placeholder="cherche la page" v-model="searchQuery">
            <button @click=searchPage(searchQuery)>Appliquer</button>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Nom </th>
                        <th>Cellule</th>
                        <th>Présence</th>
                        <th>Observation</th>
                        <th>Accompagnement</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody v-if="filteredMemberAttendance.length > 0">
                    <tr v-for="item in filteredMemberAttendance" :key="item.id">
                        <td>{{ item.member_name }}</td>
                        <td>{{ item.cell_name }}</td>

                        <td v-if="item.attendance === 'present'">Présent</td>
                        <td v-else-if="item.attendance === 'absent'">Absent</td>
                        <td v-else>Justifié</td>

                        <td>{{ item.observation }}</td>

                        <td v-if="item.Followup === 'phone_Followup'">Par téléphone</td>
                        <td v-else-if="item.Followup === 'physical_Followup'">Physique</td>
                        <td v-else>no</td>

                        <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="parent-table">
        <h3>Les rapports des cellules</h3>
        <div class="header-table">
            <input type="text" placeholder="rechercher..." v-model="searchTextCellRepo">
            <input type="text" placeholder="cherche la page" v-model="searchQueryCellRepo">
            <button @click=searchPageCellRepo(searchQueryCellRepo)>Appliquer</button>
            <p>{{ errorMessageCellRepo }}</p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Cellule</th>
                        <th>Visitors save</th>
                        <th>Visitors no save</th>
                        <th>save pendant le cellule</th>
                        <th>Facilitateur</th>
                        <th>Moderateur</th>
                        <th>Commentaire</th>
                        <th>Evénement après Mardi</th>
                        <th>Suggestion & ulgence</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody v-if="filteredCellReport.length > 0">
                    <tr v-for="item in filteredCellReport" :key="item.id">
                        <td>{{ item.cell_name }}</td>
                        <td>{{ item.saved_visitors }}</td>
                        <td>{{ item.no_saved_visitors }}</td>
                        <td>{{ item.saved_during_cell }}</td>
                        <td>{{ item.word_facilitator }}</td>
                        <td>{{ item.moderator }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.after_cell_event }}</td>
                        <td>{{ item.suggestion_or_emergency }}</td>
                        <td>{{ moment(item.cell_date).format("DD-MM-YYYY") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>Pas de Resultat disponible</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import moment from 'moment';

export default {
    // props: {
    //   members: Array,
    //   cell: Array
    // },
    data() {
        return {
            moment: moment,

            selectedDate: new Date().toISOString().substr(0, 10),
            filterType: "all",

            searchText: "",
            errorMessage: '',
            previousUrl: null,
            nextUrl: null,
            currentPage: 1,
            totalPages: 1,
            pageSize: 40,
            searchQuery: 1,

            searchTextCellRepo: "",
            errorMessageCellRepo: '',
            previousUrlCellRepo: null,
            nextUrlCellRepo: null,
            currentPageCellRepo: 1,
            totalPagesCellRepo: 1,
            pageSizeCellRepo: 40,
            searchQueryCellRepo: 1,
        }
    },
    components: {
        // moment,
    },
    methods: {
        incrementPage() {
            if (this.nextUrl) {
                this.currentPage++;
                this.getMemberAttendance(this.nextUrl);
                this.searchQuery = this.currentPage;
            }
        },
        decrementPage() {
            if (this.previousUrl) {
                this.currentPage--;
                this.getMemberAttendance(this.previousUrl);
                this.searchQuery = this.currentPage;
            }
        },
        getMemberAttendance(url = null) {
            if (!url) {
                url = '/all-member-attendance/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.memberAttendance = response.data.results
                    // Update pagination variables
                    this.previousUrl = response.data.previous;
                    this.nextUrl = response.data.next;
                    this.totalPages = Math.ceil(response.data.count / this.pageSize);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPage(searchQuery) {
            const pageNumber = parseInt(searchQuery);
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.getMemberAttendance(`/all-member-attendance/?page=${pageNumber}`);
            } else {
                this.errorMessage = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessage = null;
                }, 2500);
            }
        },
        incrementPageCellRepo() {
            if (this.nextUrlCellRepo) {
                this.currentPageCellRepo++;
                this.getCellReport(this.nextUrlCellRepo);
                this.searchQueryCellRepo = this.currentPageCellRepo;
            }
        },
        decrementPageCellRepo() {
            if (this.previousUrlCellRepo) {
                this.currentPageCellRepo--;
                this.getCellReport(this.previousUrlCellRepo);
                this.searchQueryCellRepo = this.currentPageCellRepo;
            }
        },
        getCellReport(url = null) {
            if (!url) {
                url = '/cell-report/';
            }
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    // Update member attendance data
                    this.$store.state.cellReport = response.data.results
                    // Update pagination variables
                    this.previousUrlCellRepo = response.data.previous;
                    this.nextUrlCellRepo = response.data.next;
                    this.totalPagesCellRepo = Math.ceil(response.data.count / this.pageSizeCellRepo);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchPageCellRepo(searchQueryCellRepo) {
            const pageNumberCellRepo = parseInt(searchQueryCellRepo);
            if (pageNumberCellRepo >= 1 && pageNumberCellRepo <= this.totalPagesCellRepo) {
                this.currentPageCellRepo = pageNumberCellRepo;
                this.getCellReport(`/cell-report/?page=${pageNumberCellRepo}`);
            } else {
                this.errorMessageCellRepo = 'Page invalide!';
                setTimeout(() => {
                    this.errorMessageCellRepo = null;
                }, 2500);
            }
        },
    },
    computed: {
        filteredMemberAttendance() {
            return this.searchInArray(this.$store.state.memberAttendance, this.searchText);
        },
        filteredCellReport() {
            return this.searchInArray(this.$store.state.cellReport, this.searchTextCellRepo)
        },
    },
    mounted() {
        this.getMemberAttendance();
        this.getCellReport();
    },
}
</script>

<style>
.submit {
    background-color: #4285f4;
    color: white;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.submit:hover {
    background-color: #fff;
    color: #4285f4;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 8px;
}

.rapport {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
</style>